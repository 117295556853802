import React, { FunctionComponent } from 'react';
import UserFeedback from 'networking/models/UserFeedback';
import Checkbox from 'components/utils/Checkbox';
import UserFeedbackCard from './UserFeedbackCard';
import Pagination from 'components/layout/pagination/Pagination';

interface Props {
    feedback: UserFeedback[];
}

const UserFeedbackList: FunctionComponent<Props> = (props) => {

    return (
        <div className={'observer-list observer-list'}>
            <div className={'observer-list__header observer-list__grid--feedback'}>
                <span className="observer-list__title">#</span>
                <span className="observer-list__title">Titel</span>
                <span className="observer-list__title">Melder</span>
                <span className="observer-list__title">Meldkamer</span>
                <span className="observer-list__title">Datum</span>
                <span className="observer-list__title">Afgehandeld</span>
            </div>

            {props.feedback.map((feedback) => (
                <UserFeedbackCard feedback={feedback} key={feedback.id} />
            ))}
        </div>
    );
};

export default UserFeedbackList;