import React, {FunctionComponent, KeyboardEvent, useState} from 'react';

import ModalBody, { ModalStyle } from './ModalBody';
import {useTheme} from '../../../contexts/ThemeContext';

export const useModal = (): [boolean, boolean, () => void] => {
    const [open, setOpen] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [activeTimeout, setActiveTimeout] = useState<any>(null);

    const toggleModal = () => {
        if(!open) {
            clearTimeout(activeTimeout);

            setActive(true);
            setOpen(true);
        } else {
            setOpen(false);

            const timeout = setTimeout(() => {
                setActive(false);
            }, 490);

            setActiveTimeout(timeout);
        }
    };

    return [open, active, toggleModal];
};

interface Props {
    children: any;
    isOpen: boolean;
    active: boolean;
    onCloseClick: () => void;
    hidden?: boolean;
    onKeyPress?: (e: KeyboardEvent<HTMLElement>) => void;
    className?: string;
    style?: ModalStyle;
    customBody?: boolean;
    flexible?: boolean;
}

const Modal: FunctionComponent<Props> = ({ flexible, customBody, style, children, isOpen, active, onCloseClick, hidden = true, onKeyPress, className }) => {
    const { theme } = useTheme();

    const getClassName = () => {
        return `
            modal modal--${theme.modifier} 
            modal--${isOpen ? 'open' : 'closed'} 
            modal--${active ? 'active' : 'inactive'} 
            ${className} 
            ${hidden && !active ? 'modal--hidden' : ''}
            ${flexible ? 'modal--flexible' : ''}
        `;
    };

    const getBody = () => {
        if(customBody) {
            return children;
        }

        return (
            <ModalBody onCloseClick={onCloseClick} style={style}>
                { children }
            </ModalBody>
        );
    };

    return (
        <div className={getClassName()} onKeyUp={onKeyPress} tabIndex={0}>
            <div className={'modal__overlay'} />

            { getBody() }
        </div>
    );
};

export default Modal;
