import React, {ChangeEvent, DragEvent, FunctionComponent, useRef, useState} from 'react';

import {ReactComponent as UploadIcon} from 'assets/icons/upload.svg';

import CheckIcon from '../../../utils/CheckIcon';
import {ReactComponent as DeleteIcon} from 'assets/icons/delete.svg';
import {useTheme} from '../../../contexts/ThemeContext';
import {isDev} from '../../../../helpers/Env';

interface Props {
    onFileDrop: (file: File, type: string) => void;
    allowedExtensions?: string[];
    uploadProgress: number;
    uploadBarColor: 'green' | 'blue';
    onDeleteClick: () => void;
    uploadAllowed: boolean;
}

const UploadArea: FunctionComponent<Props> = ({uploadAllowed, onDeleteClick, uploadBarColor, onFileDrop, allowedExtensions, uploadProgress}) => {
    const {theme} = useTheme();

    const [draggedOver, setDraggedOver] = useState<boolean>(false);
    const [currentFileName, setCurrentFileName] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onUploadClick = () => {
        if (!fileInputRef.current || !uploadAllowed) return;

        fileInputRef.current.click();
    };

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (!e.dataTransfer || !uploadAllowed) return;

        setDraggedOver(false);

        const files = e.dataTransfer.items;

        for (let i = 0; i < files.length; i++) { // for of does not work in this situation
            if (files[i].kind !== 'file') continue;

            const file: File | null = files[i].getAsFile();

            if (!file) continue;

            uploadFile(file);
        }
    };

    const uploadFile = (file: File) => {
        const extensions = file.name.split('.');
        const extension = extensions[extensions.length - 1];

        if (!extension) return;

        if (allowedExtensions && !allowedExtensions.includes(extension)) {
            return;
        }

        setCurrentFileName(file.name);
        onFileDrop(file, extension);
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragEnter = () => {
        if (draggedOver) return;

        setDraggedOver(true);
    };

    const onDragLeave = () => {
        if (!draggedOver) return;

        setDraggedOver(false);
    };

    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        uploadFile(e.target.files[0]);

        e.target.value = '';
    };

    const extensions = allowedExtensions ? allowedExtensions.map((ex) => `.${ex}`) : [];


    const onFileDeleteClick = () => {
        setCurrentFileName('');
        onDeleteClick();
    };

    return (
        <div className={`upload-area upload-area--${theme.modifier} ${draggedOver ? 'upload-area--dark' : ''}`}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}>

            <div className={'upload-area__upload-bar-container'}>
                <div className={`upload-area__upload-bar upload-area__upload-bar--${uploadBarColor}`}
                    style={{width: `${uploadProgress}%`}}/>
            </div>

            {(uploadProgress === 100) &&
                <div className={'upload-area__check-icon-container'}>
                    <span className={'upload-area__filename'}>{currentFileName}</span>
                    <CheckIcon/>

                    <DeleteIcon className={'upload-area__delete-icon'} onClick={onFileDeleteClick}/>
                </div>
            }

            <div className={'upload-area__icon-container'}>
                <UploadIcon className={'upload-area__icon'}/>
            </div>

            <span className={'upload-area__text'}>
                {isDev() &&
                    <p className={'upload-area__text--warning'}>Hier mogen geen echte bestanden worden geüpload!</p>
                }

                Sleep hier je bestand, of <span
                    className={`upload-area__text--upload upload-area__text--${uploadAllowed ? 'pointer' : 'default'}`}
                    onClick={onUploadClick}>upload</span>
            </span>

            <input type="file" style={{display: 'none'}} ref={fileInputRef} onChange={onFileInputChange}
                accept={extensions.join(',')}/>
        </div>
    );
};

export default UploadArea;
