import React, {useEffect, useState} from 'react';
import {TabContent} from 'buro-lib-ts';
import {useQuery} from '@tanstack/react-query';
import {confirmAlert} from 'react-confirm-alert';

import Page from 'components/layout/Page';
import ConversationsContent from './ConversationsContent';
import BackButton from '../../../layout/buttons/BackButton';

import Tabs from 'components/layout/tabs/Tabs';
import TabBar from 'components/layout/tabs/TabBar';
import TabBarHeader from 'components/layout/tabs/TabBarHeader';
import TabBarNavigation from 'components/layout/tabs/TabBarNavigation';
import Tab from 'components/layout/tabs/Tab';

import Button from '../../../layout/buttons/Button';

import {success, warn} from '../../../../helpers/Toast';
import {useAuth} from '../../../contexts/AuthContext';
import {logError} from '../../../utils/devtool/DevTool';
import {useAuditCallIndexQueryConfig} from '../../../query/AuditCall/Query';
import {useAuditMutations} from '../../../query/Audit/Mutation';
import {useAuditDetailQueryConfig, useAuditScoreQueryConfig} from '../../../query/Audit/Query';
import MeetingsContent from '../../partials/conversations/meetings/MeetingsContent';
import {useMeetingIndexQueryConfig} from '../../../query/Meeting/Query';
import {set112Timeout} from '../../../../helpers/Time';
import AuditRepository from '../../../../networking/repos/AuditRepository';
import {FormErrorRepeat} from './AuditCriteriaBox';
import {createRoute, useParams, useRouter} from '@tanstack/react-router';
import {AuditsRoute} from '../audits/Audits';

export const ConversationsRoute = createRoute({
    getParentRoute: () => AuditsRoute,
    path: '/$auditId',
    component: () => <Conversations />,
});

// TODO: TEST QUERY
const Conversations= () => {
    const {user, isAuditor, isCenterAdmin, isCoach} = useAuth();
    const {auditId} = useParams({strict: false}) as {auditId: number};

    const router = useRouter();

    const [meetingConfig] = useMeetingIndexQueryConfig(auditId);
    const meetingQuery = useQuery(meetingConfig);

    const {finishAudit} = useAuditMutations(auditId);

    const [auditConfig] = useAuditDetailQueryConfig(auditId);
    const [auditCallConfig] = useAuditCallIndexQueryConfig(auditId);
    const [auditScoreConfig] = useAuditScoreQueryConfig(auditId);

    const auditQuery = useQuery({...auditConfig, retry: 1});
    const auditCallQuery = useQuery({...auditCallConfig, retry: 1});

    const changeableAudit = auditQuery.data;
    const isNotAuditor = () => {
        return user!.id !== changeableAudit?.auditor_id;
    };

    const auditScoreQuery = useQuery({
        ...auditScoreConfig,
        enabled: !!auditQuery.data && (auditQuery.data.completed && (isNotAuditor() || isCenterAdmin() || isCoach()))
    });

    const [finishRequestPending, setFinishRequestPending] = useState<boolean>(false);

    const [formErrorRepeat, setFormErrorRepeat] = useState<FormErrorRepeat>({knockout: 0, error_repeat: 0});
    const [auditRepository] = [
        new AuditRepository()
    ];
    useEffect(() => {
        if (!auditQuery.data) return;
        auditRepository.getForm(auditQuery.data).then(form => setFormErrorRepeat({
            knockout: form.max_knockout_count,
            error_repeat: form.max_error_count
        }));
    }, [auditQuery.data, setFormErrorRepeat]);

    const auditCalls = auditCallQuery.data ?? [];
    const meetings = meetingQuery.data ?? [];

    useEffect(() => {
        if (auditQuery.isError) {
            warn('Audit kan niet gevonden worden');
            set112Timeout(() => {
                router.history.back();
            }, 1000);
        }
    }, [auditQuery.isError]);

    if (!changeableAudit) return null;

    const back = () => {
        router.history.back();
    };

    const onFinishClick = (sendMail: boolean) => {
        setFinishRequestPending(true);

        finishAudit.mutateAsync(sendMail).then(() => {
            success('De audit is afgerond.');
        }).catch(logError);
    };

    const submitFinishAudit = () => {
        if (finishRequestPending) return;

        confirmAlert({
            customUI: ({onClose}) => (
                <div className='custom-alert'>
                    <p>Wilt u een mailtje laten versturen?</p>
                    <button
                        className={'button'}
                        onClick={() => {
                            onFinishClick(false);
                            onClose();
                        }}>Nee
                    </button>

                    <button
                        className={'button button-basic--light'}
                        onClick={() => {
                            onFinishClick(true);
                            onClose();
                        }}>Ja
                    </button>
                </div>
            )
        });
    };

    const canFinishAudit = () => {
        return auditCalls.filter(call => call.completed_at).length >= changeableAudit.required_calls;
    };

    const AuditStatus = () => {
        const baseClassName = 'audit__status audit__status--small m-left-2';

        if (changeableAudit.completed_at === null) {
            return isAuditor() && user?.id === changeableAudit.auditor_id
                ? <Button onClick={submitFinishAudit} disabled={!canFinishAudit()}
                    className={'m-left-2'}>Afronden</Button>
                : <div
                    className={'audit__status audit__status--todo audit__status--small m-left-2'}>To do</div>;
        }

        if (isNotAuditor() || isCenterAdmin() || isCoach()) {
            return !changeableAudit.passed
                ? <div
                    className={`${baseClassName} audit__status--denied`}>Onder de norm</div>
                : <div
                    className={`${baseClassName} audit__status--approved`}>Op/boven de norm</div>;
        }

        return <div className={`${baseClassName} audit__status--done`}>Afgerond</div>;
    };

    return (
        <Page className={`conversations-page`}>
            <Tabs scrollable>
                <TabBar>
                    <TabBarHeader>
                        <div className={'flex flex--justify-between'}>
                            <BackButton onClick={back}/>

                            <AuditStatus/>
                        </div>
                    </TabBarHeader>

                    <TabBarNavigation>
                        <Tab>Audit</Tab>
                        <Tab>Vergaderingen</Tab>
                    </TabBarNavigation>
                </TabBar>

                <TabContent>
                    <ConversationsContent audit={changeableAudit} auditCalls={auditCalls}
                        scores={auditScoreQuery.data || []} formErrorRepeat={formErrorRepeat}/>
                </TabContent>
                <TabContent>
                    <MeetingsContent auditId={changeableAudit.id!} meetings={meetings}/>
                </TabContent>
            </Tabs>
        </Page>
    );
};

export default Conversations;
