import React, {FunctionComponent} from 'react';
import {createRoute, useLocation, useNavigate} from '@tanstack/react-router';
import Page from 'components/layout/Page';
import AuthRepository from '../../../../networking/repos/AuthRepository';
import {warn} from 'helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {useCentersSsoQueryConfig} from '../../../query/Center/Query';
import {useQuery} from '@tanstack/react-query';
import StylessButton from '../../../layout/buttons/StylessButton';
import {LoginRoute} from './Login';
import {GuestRoute} from '../../../../routing/Auth';

interface Center {
    id: number;
    name: string;
    image_url: string;
}

export const LandingRoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/',
    component: () => <Landing/>,
});

const Landing: FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [authRepository] = [
        new AuthRepository()
    ];

    const [centerSsoConfig] = useCentersSsoQueryConfig();
    const centerSso = useQuery(centerSsoConfig);

    const handleClick = async (id: number) => {

        const redirectTo = location.pathname;
        sessionStorage.setItem('redirectTo', redirectTo);

        try {
            const ssoRequest = await authRepository.getSsoRedirectUrl(id);
            if (ssoRequest.url) {
                window.location.href = ssoRequest.url;
            } else {
                return navigate({to: LoginRoute.fullPath});
            }
        } catch (e) {
            return logError(e) && warn('Oeps, er is iets misgegaan.');
        }
    };

    return (
        <Page className="auth__page">
            <div className="auth__page__form">
                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">
                        Selecteer uw meldkamer
                    </h1>
                </div>
                <div className="auth__page__form__row">
                    <div className="auth__page__form__grid">
                        {centerSso.data?.map((center: Center) =>
                            <StylessButton key={center.id} className="auth__page__form__grid__item"
                                onClick={() => handleClick(center.id)}>
                                <img src={center.image_url} alt={'Center'}/>
                                {center.name}
                            </StylessButton>
                        )}
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Landing;
