import React, {ChangeEvent, FunctionComponent, useState} from 'react';

interface Props {
    id: string,
    value: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onKeyUp?: (e: React.KeyboardEvent) => void;
    label: boolean,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    password?: boolean,
    labelName?: string
}

const IconInput: FunctionComponent<Props> = (props) => {
    const [, setFocus] = useState<boolean>(false);

    return (
        <div className="input input--icon">
            {props.label && <p className="input__label">{props.labelName}</p>}

            <div className="input--icon__field-cont">
                <input
                    id={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    onKeyUp={props.onKeyUp}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    type={props.password ? 'password' : 'text'}
                    className="input__field input__field--icon"
                />

                <props.icon className="input--icon__field-cont__icon" />
            </div>
        </div>
    );
};

export default IconInput;
