import React, {ReactElement, RefAttributes, useState} from 'react';
import {Input as BoilerInput, BoilerInputProps, ClassBuilder } from 'buro-lib-ts';

import { ReactComponent as KeyIcon } from 'assets/icons/key-password.svg';
import { ReactComponent as HideIcon } from 'assets/icons/hide-password.svg';
import { ReactComponent as ShowIcon } from 'assets/icons/show-password.svg';
import InputContainer from './InputContainer';

interface Props<T> extends BoilerInputProps<T> {
    text: string;
    size?: string;
    onKeyPress?: (e:React.KeyboardEvent) => void;
    password?: boolean;
    icon?: string;
    onBlur?: () => void;
    disabled?: boolean;
    onKeyUp?: (e: React.KeyboardEvent) => void;
}

function InputComponent<T>(props: Props<T>, ref: any) {
    const { className, size, text, onChange, password, icon } = props;

    const [focused, setFocused] = useState<boolean>(false);
    const [showValue, setShowValue] = useState<boolean>(false);

    const toggleFocus = (): void => {
        setFocused(!focused);
    };

    const toggleShowValue = (): void => {
        setShowValue(!showValue);
    };

    const getClassName = (): string => {
        return ClassBuilder.createClass(`default-input--${size}`)
            .addIf(className, className)
            .build();
    };

    const getType = () => {
        if(!password) {
            return props.type || 'text';
        }

        return showValue ? 'text' : 'password';
    };

    const getToggleIcon = () => {
        const Icon = showValue ? HideIcon : ShowIcon;

        return <Icon
            className={'icon-grey icon-grey--filled default-input__icon default-input__icon--right'}
            onClick={toggleShowValue} />;
    };

    return (
        <InputContainer text={text} className={getClassName()} icon={password ? KeyIcon : props.icon}>
            <BoilerInput
                {...props}
                type={getType()}
                className={`input__field ${props.form.errors[props.id]?.length ? 'input__field--error' : ''} default-input__field ${password && 'default-input__field--password'} ${icon && 'default-input__field--icon'}`}
                onFocus={toggleFocus}
                onBlur={toggleFocus}
                onChange={onChange}
                placeholder={text}
                ref={ref}
            />

            { password &&
                getToggleIcon()
            }
        </InputContainer>
    );
}

const Input = React.forwardRef(InputComponent) as unknown as
    <T>(props: Props<T> & RefAttributes<HTMLInputElement>) => ReactElement;

export default Input;
