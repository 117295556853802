import {useQuery, UseQueryResult} from '@tanstack/react-query';

import UserRepository from '../../../networking/repos/UserRepository';
import {useIndexQueryConfig, useQueryConfig} from '../Utils';
import {UserIndexResponse} from '../../../networking/responses/User';

export type UserQueryResult = UseQueryResult<UserIndexResponse>;

export const useUserIndexQuery = (center: number) => {
    const [config] = useUserIndexQueryConfig(center);

    return useQuery(config);
};

export const useUserShowQuery = (center: number, userId: number) => {
    const repo = new UserRepository(center);
    const [config] = useQueryConfig(['user', userId], () => repo.get(userId));

    return useQuery(config);
};

export const useUserIndexQueryConfig = (center: number) => {
    const repo = new UserRepository(center);

    return useIndexQueryConfig(['users'], () => repo.all());
};

export const useUserRoleAuditorQueryConfig = (center: number) => {
    const repo = new UserRepository(center);

    return useIndexQueryConfig(['auditors'], () => repo.getAuditors());
};

export const useUserRoleOperatorQueryConfig = (center: number) => {
    const repo = new UserRepository(center);

    return useIndexQueryConfig(['operators'], () => repo.getTriagists());
};

export const useUserRoleAdminQueryConfig = (center: number) => {
    const repo = new UserRepository(center);

    return useIndexQueryConfig(['center-admins'], () => repo.getAdmins());
};

export const useUserIndexWithBlockedQueryConfig = (center: number, role?: string) => {
    const repo = new UserRepository(center);

    return useIndexQueryConfig(['users-with-blocked', role], () => repo.allWithBlocked(role));
}