import React, {FunctionComponent, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useAuth} from '../../../contexts/AuthContext';
import User from '../../../../networking/models/User';
import {success, warn} from '../../../../helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {UserForm} from '../../../form/User';
import {useUserIndexWithBlockedQueryConfig} from '../../../query/User/Query';
import {useUserCollectionMutations} from '../../../query/User/Mutation';
import UserTabs from '../../partials/users/UserTabs';
import {createRoute} from '@tanstack/react-router';
import {UserRoute} from '../../../../routing/User';

interface TabState {
    searchQuery: string;
    title: string;
    filter?: (value: User, index: number, array: User[]) => boolean;
}

export const UsersRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/users',
    component: () => <Users />,
});

const Users: FunctionComponent = () => {
    const {user} = useAuth();

    const {
        createUser,
        updateUser,
        softDeleteUser,
        restoreUser
    } = useUserCollectionMutations(user?.center!);

    const [userConfig] = useUserIndexWithBlockedQueryConfig(user?.center!);
    const userQuery = useQuery(userConfig);

    const getUserFilterFunctionForRole = (role: string): (user: User) => boolean => {
        return (user) => {
            return user.roles.findIndex((r) => r.role === role) !== -1 && !user.blocked;
        };
    };

    const [tabs, setTabs] = useState<TabState[]>([
        {searchQuery: '', title: 'Alle gebruikers', filter: (user: User) => (!user.blocked)},
        {searchQuery: '', title: 'Beheerders', filter: getUserFilterFunctionForRole('centerAdmin')},
        {searchQuery: '', title: 'Auditors', filter: getUserFilterFunctionForRole('auditor')},
        {searchQuery: '', title: 'Triagisten', filter: getUserFilterFunctionForRole('operator')},
        {searchQuery: '', title: 'Medische specialisten', filter: getUserFilterFunctionForRole('coach')},
        {searchQuery: '', title: 'Geblokkeerde Gebruikers', filter: (user: User) => (user.blocked)},
    ]);

    const onUserCreate = async (savedUser: UserForm, roles: string[]) => {
        createUser.mutateAsync({...savedUser, roles})
            .then(() => success('Gebruiker is opgeslagen.'))
            .catch((e) => {
                logError(e);
                warn('Oeps, er is iets misgegaan!');
            });
    };

    const onUserUpdate = (user: User, data: UserForm, roles: string[]) => {
        updateUser.mutateAsync({id: user.id!, request: {...data, roles}})
            .then(() => success('Gebruiker is bijgewerkt.'))
            .catch((e) => {
                logError(e);
                warn('Oeps, er is iets misgegaan!');
            });
    };

    const onUserSoftDelete = async (user: User) => {
        softDeleteUser.mutateAsync(user.id!)
            .then(() => success('Gebruiker is geblokkeerd.'))
            .catch((e: any) => {
                logError(e);
                warn('Oeps, er is iets misgegaan!');
            });
    };

    const onUserRestore = async (user: User) => {
        restoreUser.mutateAsync(user.id!)
            .then(() => success('Gebruiker is gedeblokkeerd.'))
            .catch((e: any) => {
                logError(e);
                warn('Oeps, er is iets misgegaan!');
            });
    };

    return (
        <UserTabs tabs={tabs} setTabs={setTabs} onUserCreate={onUserCreate} onUserUpdate={onUserUpdate}
            onUserSoftDelete={onUserSoftDelete} onUserRestore={onUserRestore} users={userQuery.data ?? []}/>
    );
};

export default Users;
