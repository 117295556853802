import React, {FormEvent, FunctionComponent, useEffect, useState} from 'react';
import {check, FormChangedEvent, input, useForm, V} from 'buro-lib-ts';
import {Link} from '@tanstack/react-router';

import Input from '../../partials/input/Input';
import Select from '../../partials/input/select/Select';
import User from '../../../../networking/models/User';
import MailIcon from '../../../../assets/icons/message.svg';
import PhoneIcon from '../../../../assets/icons/phone.svg';
import Center from '../../../../networking/models/Center';
import Form from '../../../../networking/models/Form';
import {success, warn} from '../../../../helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {
    useCenterAnonymousAuditorsMutation,
    useCenterAttachFormsMutation,
    useCenterUpdateMutation
} from '../../../query/Center/Mutation';
import {MV, parseErrors} from '../../../../helpers/Validation';
import Button from '../../../layout/buttons/Button';
import {FormRoute} from '../form/Form';

interface Props {
    center: Center;
    centerForms: Form[];
    forms: Form[];
    centerAdmins: User[];
}

// TODO: Fix
const CenterSettingsContent: FunctionComponent<Props> = (props) => {
    const {center, centerForms, forms, centerAdmins} = props;

    const updateCenter = useCenterUpdateMutation(center.id);
    const toggleAnonymousAuditors = useCenterAnonymousAuditorsMutation(center.id);
    const attachForms = useCenterAttachFormsMutation(center.id);

    const [isSaved, setIsSaved] = useState<boolean>(false);

    const [selectedContact, setSelectedContact] = useState<number>(center.contact_id ?? undefined);

    const [form, onFormChange] = useForm({
        name: input(center.name, [V.required, V.size(1, 20)]),
        city: input(center.city, [V.required, V.size(1, 20)]),
        street: input(center.street, [V.required, V.size(1, 20)]),
        housenumber: input(center.housenumber, [MV.houseNumber()]),
        postal_code: input(center.postal_code, [MV.validDutchPostalCode()]),
        phonenumber: input(center.phonenumber, [V.required, MV.phoneNumber()]),
        email: input(center.email, [V.required, V.email]),
        anonymous_auditors: check(center.anonymous_auditors),
        sso: check(center.sso),
        nts_form: check(center.nts_form),
        audit_term: input(center.audit_term?.toString(), [V.required]),
    });

    let setTimeOutSavedStatus: any;

    useEffect(() => {
        clearTimeout(setTimeOutSavedStatus);
    }, [form.values]);

    const onSwitchSelect = (form: Form) => {
        const forms: Form[] = [...centerForms];

        const index = centerForms.findIndex(centerForm => centerForm.id === form.id);

        if (index === -1) {
            forms.push(form);
        } else {
            forms.splice(index, 1);
        }

        attachForms.mutateAsync(forms).then(() => {
            success('Wijzigingen opgeslagen.');
        }).catch((e: any) => {
            logError(e);
            warn('Er is een fout opgetreden.');
        });
    };

    const onAnonymousSwitchSelect = () => {
        toggleAnonymousAuditors.mutateAsync().then(() => {
            success('Wijzigingen opgeslagen.');
        }).catch((e) => {
            logError(e);
            warn('Er is een fout opgetreden.');
        });
    };

    const onContactSelect = (selectedUser: any) => {
        setSelectedContact(selectedUser ? selectedUser.id : null);
    };

    const onClick = (event: FormEvent) => {
        event.preventDefault();
        if (!form.isValid) return;

        updateCenter.mutateAsync({
            ...form.values,
            contact_id: selectedContact
        }).then(() => {
            setIsSaved(true);

            setTimeOutSavedStatus = setTimeout(function () {
                setIsSaved(false);
            }, 5000);

            success('Wijzigingen zijn opgeslagen.');
        }).catch((e: any) => {
            logError(e);
            warn('Er is een fout opgetreden.');
        });
    };

    function parseMessages() {
        const messageList = parseErrors(form.errors, {
            name: 'naam',
            city: 'plaatsnaam',
            street: 'straatnaam',
            housenumber: 'huisnummer',
            postal_code: 'postcode',
            phonenumber: 'telefoonnummer',
            email: 'e-mail',
            audit_term: 'audit termijn'

        }) || null;


        // return the list of messages mapped to li elements
        if (messageList == null) return null;
        return messageList.map((message, index) => {
            return <li key={index}>{message}</li>;
        });
    }

    function getErrors() {
        return (
            <ul className={'audit-create-box__errors'}>
                {parseMessages()}
            </ul>

        );
    }

    return (
        <React.Fragment>
            <div className="settings-page__section">
                <h1 className="heading4--black">Gegevens meldkamer</h1>
                <p>Pas hier de gegevens aan van de meldkamer</p>
                <form className="settings-page__form" action={'#'}>

                    <div className="form-row">
                        <Input
                            id={'name'}
                            text={'Naam meldkamer'}
                            size={'small'}
                            form={form}
                            onChange={onFormChange}
                        />
                    </div>

                    <div className="form-row">
                        <Input
                            id={'street'}
                            text={'Straatnaam'}
                            size={'small'}
                            form={form}
                            onChange={onFormChange}
                        />
                        <Input
                            id={'housenumber'}
                            text={'Huisnummer + toevoeging'}
                            size={'small'}
                            form={form}
                            onChange={onFormChange}
                        />

                        <Input
                            id={'postal_code'}
                            text={'Postcode'}
                            size={'small'}
                            form={form}
                            onChange={onFormChange}
                        />
                        <Input
                            id={'city'}
                            text={'Plaatsnaam'}
                            size={'small'}
                            form={form}
                            onChange={onFormChange}
                        />
                    </div>

                    <div className="form-row">
                        <Select
                            placeholder={'Kies een contactpersoon'}
                            options={centerAdmins}
                            selected={centerAdmins.findIndex((admin: User) => admin.id === selectedContact)}
                            onSelect={onContactSelect} title={'Contactpersoon'}
                            renderOption={(admin: User) => admin.first_name + ' ' + admin.last_name}/>

                        <Input
                            id={'email'}
                            text={'E-mail'}
                            size={'small'}
                            form={form}
                            icon={MailIcon}
                            onChange={onFormChange}
                        />
                        <Input
                            id={'phonenumber'}
                            text={'Telefoonnummer'}
                            size={'small'}
                            form={form}
                            icon={PhoneIcon}
                            onChange={onFormChange}
                        />
                        <Input
                            id={'audit_term'}
                            text={'Audit termijn (in weken)'}
                            size={'small'}
                            form={form}
                            onChange={(e: FormChangedEvent<any>) => {
                                if (isNaN(Number(e.value)))
                                    return;

                                onFormChange(e);
                            }}
                        />
                    </div>


                    {getErrors()}

                    <Button type={'button'} disabled={!form.isValid} onClick={onClick}>{isSaved ? 'Opgeslagen' : 'Opslaan'}</Button>
                </form>
            </div>

            <div className="settings-page__section">
                <h1 className="heading4--black">Anonieme Auditors</h1>
                <p>Indien onderstaande optie is aangevinkt zal nergens de naam van de auditor bij een audit zichtbaar
                    zijn. Tenzij u een beheerder bent, dan zijn ze voor u wel zichtbaar.</p>
                <form className="settings-page__form settings-page__forms">
                    <div className="form-group settings-page__form-toggle">
                        <label>Anonieme auditors</label>
                        <label className="switch">
                            <input type="checkbox" onChange={onAnonymousSwitchSelect}
                                checked={center.anonymous_auditors}/>
                            <span className="switch__slider switch__round"/>
                        </label>
                    </div>
                </form>
            </div>

            <div className="settings-page__section">
                <h1 className="heading4--black">Formulieren</h1>
                <form className="settings-page__form settings-page__forms">
                    {
                        forms.map((form) => {
                            return (
                                <div className="form-group settings-page__form-toggle" key={form.id}>
                                    <Link to={FormRoute.fullPath} params={{formId: form.id}}>
                                        <label className="settings-page__form-link">{form.name}</label>
                                    </Link>
                                    <label className="switch">
                                        <input type="checkbox" onChange={() => onSwitchSelect(form)}
                                            checked={!!centerForms.find(centerForm => centerForm.id === form.id)}/>
                                        <span className="switch__slider switch__round"/>
                                    </label>
                                </div>
                            );
                        })
                    }
                </form>
            </div>
        </React.Fragment>
    );
};

export default CenterSettingsContent;
