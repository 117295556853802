import React, {ChangeEvent, FormEvent, useEffect, useMemo, useState} from 'react';
import {input, useForm} from 'buro-lib-ts';
import Page from 'components/layout/Page';
import Input from 'components/client/partials/input/Input';
import IconInput from 'components/client/partials/input/IconInput';
import BackButton from '../../../layout/buttons/BackButton';
import Button from 'components/layout/buttons/Button';
import {ReactComponent as Arrow} from '../../../../assets/icons/arrow.svg';
import {ReactComponent as Message} from '../../../../assets/icons/message.svg';
import {ReactComponent as KeyPassword} from '../../../../assets/icons/key-password.svg';
import {useAuth} from 'components/contexts/AuthContext';
import AuthRepository from '../../../../networking/repos/AuthRepository';
import User from '../../../../networking/models/User';
import {Roles, userHasRole} from '../../../../helpers/Auth';
import {createRoute, Link, useNavigate} from '@tanstack/react-router';
import {DashboardRoute} from '../dashboard/Dashboard';
import {CentersPageRoute} from '../../../admin/pages/centers/CentersPage';
import {LandingRoute} from './Landing';
import {PasswordResetRoute} from './password/PasswordReset';
import {GuestRoute} from '../../../../routing/Auth';

class Credentials {
    email = '';
    password = '';
}

interface OtpForm {
    otp: string;
}

export const LoginRoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/login',
    component: () => <Login />,
});

// TODO: TEST form change
const Login = () => {
    const navigate = useNavigate();
    const { login, user } = useAuth();

    const [credentials, setCredentials] = useState<Credentials>(new Credentials());
    const [loginError, setLoginError] = useState<string>('');

    const memo = useMemo(() => ({
        otp: input('')
    }), []);

    const [form, onFormChange] = useForm<OtpForm>(memo);
    const [showOtp, setShowOtp] = useState<boolean>(false);

    const authRepository = new AuthRepository();

    const clearError = () => {
        setLoginError('');
    };

    const getRedirectLocation = (user: User) => {
        if(userHasRole(user, Roles.SUPER_ADMIN)) {
            return CentersPageRoute.fullPath;
        }

        return DashboardRoute.fullPath;
    };

    const handleOtpLogin = async () => {
        if(form.values.otp === '')
            return setLoginError('Vul de code in.');

        await login({
            'email': credentials.email,
            'password': credentials.password,
            'otp': form.values.otp
        });

    };

    useEffect(()=> {
        if (user) {
            void navigate({
                to: getRedirectLocation(user)
            });
        }
    }, [user]);

    const handleEmailLogin = async () => {
        await authRepository.verifyCredentials(credentials);
        setShowOtp(true);
        clearError();
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if(credentials.email.length <= 0 || credentials.password.length <= 0)
            return setLoginError('Er zijn velden niet ingevuld.');

        try {
            if(showOtp) { // State: Showing the 2FA page.
                await handleOtpLogin();
            } else { // State: Showing the email & pass page
                await handleEmailLogin();
            }
        } catch (error: any) {
            setLoginError((error.message && error.code !== 422) ? error.message : 'Er is iets misgegaan! Heeft u alles juist ingevuld?');
        }
    };

    const handleChangeCredentials = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setCredentials({ ...credentials, [e.target.id]: e.target.value });
    };

    const handleChangeOtp = (value: string) => {
        //Max. 6 characters, only numbers.
        form.set({ otp: value.replace(/\D/g,'').substring(0, 6) });
    };

    const goBack = () => {
        setShowOtp(false);
        form.set({ otp: '' });
    };

    return (
        <Page className={'auth__page auth__page'}>
            <form className="auth__page__form" onSubmit={handleSubmit}>
                <div className="auth__page__form__row auth__page__form__row__meldkamer">
                    <div className="auth__page__form__row__col" />
                </div>

                {
                    (showOtp ?
                            <BackButton onClick={goBack}/> :
                        <Link to={LandingRoute.fullPath} className="auth__page__form__back">
                            <Arrow />
                            <p>Terug</p>
                        </Link>
                    )
                }

                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">Inloggen</h1>
                </div>

                {
                    (!showOtp) ? (
                        <React.Fragment>
                            <IconInput
                                id="email"
                                value={credentials.email}
                                onChange={handleChangeCredentials}
                                icon={Message}
                                label={true}
                                labelName="E-mail"
                                password={false}
                            />

                            <IconInput
                                id="password"
                                value={credentials.password}
                                onChange={handleChangeCredentials}
                                icon={KeyPassword}
                                label={true}
                                labelName="Wachtwoord"
                                password={true}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="auth__page__form__otp-text">Voer de code in die staat aangegeven in de Google Authenticator app.</div>

                            <Input
                                id={'otp'}
                                form={form}
                                onChange={({ value }) => handleChangeOtp(value)}
                                text="Code"
                                onKeyPress={(e:React.KeyboardEvent) => {e.key === 'Enter' && handleSubmit(e); }}
                                ref={input => input && input.focus()}
                            />
                        </React.Fragment>
                    )
                }

                <p className={loginError ? 'auth__page__form__error' : 'auth__page__form__error auth__page__form__error--hidden'}>*{loginError}</p>

                <br />

                {
                    !showOtp && (
                        <div className="auth__page__form__row auth__page__form__troubleshooting">
                            <Link to={PasswordResetRoute.fullPath} search={{email: credentials.email || undefined}}>Wachtwoord vergeten?</Link>
                        </div>
                    )
                }

                <Button className="button--inline">Inloggen</Button>
            </form>
        </Page>
    );
};

export default Login;
