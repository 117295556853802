import React, {FunctionComponent, useEffect, useState} from 'react';

import User from 'networking/models/User';
import Form from 'networking/models/Form';
import ScoresPerQuestion from 'networking/models/ScoresPerQuestion';

import {useAuth} from 'components/contexts/AuthContext';

import Select from 'components/client/partials/input/select/Select';

import OperatorScoresBox from './boxes/OperatorScoresBox';
import {ScoreFilter, useCenterScoresPerFormQueryConfig} from '../../../query/Center/Query';
import {useQuery} from '@tanstack/react-query';
import {useFormStatisticsQuery} from '../../../query/Form/Form';
import FormScoresBox from './boxes/FormScoresBox';
import CriteriaScoresBox from './boxes/CriteriaScoresBox';

import {
    ArcElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';


interface LineData {
    x: string[],
    y: {
        scoresD: number[],
        scoresN: number[],
        scoresK: number[]
    }
}

interface DoughnutData {
    yes: number,
    no: number,
    nvt: number
}

interface Props {
    forms: Form[];
    users: User[];
}

const ScoresPerWeightingContent: FunctionComponent<Props> = ({forms, users}) => {
    const {user} = useAuth();

    const [filters, setFilters] = useState<ScoreFilter>({
        form: forms[0],
        year: undefined,
        criteriumScore: undefined
    });

    const [formScoresConfig] = useCenterScoresPerFormQueryConfig(user!.center, filters);
    const formScoresQuery = useQuery(formScoresConfig);

    const [formStatisticsConfig] = useFormStatisticsQuery(user!.center, filters);
    const formStatisticsQuery = useQuery(formStatisticsConfig);

    const scoresPerQuestion = formScoresQuery.data ?? [];

    Chart.register(
        ArcElement,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    //Data we are passing to the line graph
    const [graphLineData, setGraphLineData] = useState<LineData>({
        x: [],
        y: {
            scoresD: [],
            scoresN: [],
            scoresK: []
        }
    });

    //Data we are passing to the circle diagram
    const [graphDoughnutData, setGraphDoughnutData] = useState<DoughnutData>({
        yes: 0,
        no: 0,
        nvt: 0
    });

    const [showBoxes, setShowBoxes] = useState<boolean>(false);
    const [renderGraph, setRenderGraph] = useState<boolean>(false);
    const [renderDoughnut, setRenderDoughnut] = useState<boolean>(false);

    useEffect(() => {
        setShowBoxes(!!(filters.form));

        if (!filters.form || !filters.year) {
            setRenderGraph(false);
        }

        if (!filters.form || !filters.criteriumScore) {
            setRenderDoughnut(false);
        }
    }, [filters]);

    const onFormSelect = (form: Form) => {
        setFilters({
            ...filters,
            form: form
        });

        setRenderGraph(false);
        setRenderDoughnut(false);
    };

    const onYearSelect = (selected: any) => {
        setFilters({
            ...filters,
            year: selected?.value
        });

        setRenderGraph(true);
    };

    const onQuestionSelect = (score: ScoresPerQuestion) => {
        setFilters({
            ...filters,
            criteriumScore: score
        });
    };

    const getCriteriumScores = async () => {
        setRenderDoughnut(false);

        if (!filters.form || !filters.criteriumScore) return;

        const score = scoresPerQuestion.find(score => filters.criteriumScore?.id === score.id);

        if (score) {
            setGraphDoughnutData({
                yes: parseFloat(score.yes),
                no: parseFloat(score.no),
                nvt: parseFloat(score.nvt)
            });
        }

        setRenderDoughnut(true);
    };

    useEffect(() => {
        if (!filters.form || !filters.year) return;

        setGraphLineData({
            x: formStatisticsQuery.data?.months ?? [],
            y: {
                scoresD: formStatisticsQuery.data?.scoresD ?? [],
                scoresN: formStatisticsQuery.data?.scoresN ?? [],
                scoresK: formStatisticsQuery.data?.scoresK ?? []
            }
        });

        setRenderGraph(true);
    }, [formStatisticsQuery.data]);

    return (
        <React.Fragment>
            <h2 className="statistics-page__title">Scores per weging</h2>

            <div className="statistics-page__form-filter">
                <Select title={'Kies formulier'} options={forms} onSelect={onFormSelect} initialIndex={0}
                    renderOption={(form: Form) => form.name}/>
            </div>

            {
                (showBoxes && filters.form) && (
                    <React.Fragment>
                        <FormScoresBox
                            onYearSelect={onYearSelect}
                            graphLineData={graphLineData}
                            renderLineGraph={renderGraph}
                            filters={filters}
                            center={user?.center}/>

                        <CriteriaScoresBox
                            filters={filters}
                            graphDoughnutData={graphDoughnutData}
                            renderDoughnutGraph={renderDoughnut}
                            scoresPerQuestion={scoresPerQuestion}
                            onQuestionSelect={onQuestionSelect}
                            getCriteriumScores={getCriteriumScores}/>

                        <OperatorScoresBox
                            operators={users}
                            questions={scoresPerQuestion}
                            center={user?.center!}
                            form={filters.form}/>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default ScoresPerWeightingContent;
