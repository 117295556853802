import React, {Dispatch, FunctionComponent} from 'react';
import Button from '../../../../layout/buttons/Button';
import Select from '../../../partials/input/select/Select';
import AuditList from '../../../partials/audits/AuditList';
import User from '../../../../../networking/models/User';
import moment from 'moment';
import Audit from '../../../../../networking/models/Audit';
import {AuditFilters} from '../Audits';
import {useAuth} from '../../../../contexts/AuthContext';
import Pagination, {PaginationProps} from '../../../../layout/pagination/Pagination';

export interface AuditTabProps {
    title: string;
    audits: Audit[];
    onAddClick: () => void;
    filters: AuditFilters;
    setFilters: Dispatch<AuditFilters>
    auditors?: User[];
    operators?: User[];
    paginationData: PaginationProps;
}

// TODO: TEST WORK ADD PAGINATION
const AuditTab: FunctionComponent<AuditTabProps> = ({
    title,
    audits,
    onAddClick,
    filters,
    setFilters,
    auditors,
    operators,
    paginationData
}) => {

    const {isCenterAdmin} = useAuth();

    const periods = [
        {value: 'always', render: 'Altijd'},
        {value: 'thisYear', render: 'Dit jaar'},
        {value: 'lastQuarter', render: 'Dit kwartaal'}
    ];

    const renderUserOption = (user: User) => {
        return user.first_name + ' ' + user.last_name;
    };

    const onAuditorSelect = (auditor: User) => {
        setFilters({
            ...filters,
            auditorId: auditor?.id || undefined,
        });
    };

    const onOperatorSelect = (operator: User) => {
        setFilters({
            ...filters,
            operatorId: operator?.id || undefined,
        });
    };

    // TODO: Refactor
    const onPeriodSelect = (selected: any, index: number) => {
        let startDate = '';
        let endDate = '';

        if (!selected) {
            setFilters({
                ...filters,
                startDate: undefined,
                endDate: undefined,
                periodIndex: undefined
            });

            return;
        }

        switch (selected.value) {
            case 'thisYear': {
                startDate = moment().startOf('year').format('YYYY-MM-DD');
                endDate = moment().endOf('year').format('YYYY-MM-DD');
                break;
            }

            case 'lastQuarter': {
                const quarter = moment().quarter();
                startDate = moment().quarter(quarter).startOf('quarter').format('YYYY-MM-DD').toString();
                endDate = moment().quarter(quarter).endOf('quarter').format('YYYY-MM-DD').toString();
                break;
            }
        }

        setFilters({
            ...filters,
            startDate: startDate.length ? startDate : undefined,
            endDate: endDate.length ? endDate : undefined,
            periodIndex: index || undefined
        });
    };

    return (
        <>
            <h2>{title}</h2>

            {isCenterAdmin() &&
                <div className='audits-page__search'>
                    <div className={'flex flex--justify-end w-100'}>
                        <Button onClick={onAddClick}>
                            Audit aanmaken
                        </Button>
                    </div>
                </div>}

            {/*TODO: Refactor ?? []*/}
            <div className='flex-container auditscontent-filterarea'>
                <Select placeholder={'Kies een auditor'} options={auditors ?? []} onSelect={onAuditorSelect}
                    title={'Auditor'} selectedId={filters.auditorId ?? undefined} renderOption={renderUserOption}/>
                <Select placeholder={'Kies een triagist'} options={operators ?? []} onSelect={onOperatorSelect}
                    title={'Triagist'} selectedId={filters.operatorId ?? undefined}
                    renderOption={renderUserOption}/>
                <Select placeholder={'Kies een periode'} options={periods} initialIndex={0} onSelect={onPeriodSelect}
                    title={'Periode'} selected={filters.periodIndex ?? 0}
                    renderOption={(period: any) => period.render}/>
            </div>

            <AuditList
                withAuditor={true}
                audits={audits}/>

            <Pagination
                current={paginationData.current}
                max={paginationData.max}
                setPage={paginationData.setPage}/>
        </>
    );
};

export default AuditTab;
