import React, {FunctionComponent, useEffect} from 'react';
import {Meeting} from '../../../../../../networking/models/Meeting';
import {useMeetingForm} from '../../../../../form/Meeting';
import Input from '../../../input/Input';
import CancelButton from '../../../../../layout/buttons/CancelButton';
import Button from '../../../../../layout/buttons/Button';
import {FormAccessor} from 'buro-lib-ts';
import {MeetingRequest} from '../../../../../../networking/requests/MeetingRequest';
import {parseError} from '../../../../../../helpers/Validation';
import TextArea from '../../../input/TextArea';

interface Props {
    meeting?: Meeting
    onUpdate: (id: number, form: FormAccessor<MeetingRequest>) => void
    onCreate: (form: FormAccessor<MeetingRequest>) => void
    onClose: () => void
}

export const MutateMeetingModal: FunctionComponent<Props> = ({meeting, onUpdate, onClose, onCreate}) => {
    const [form, onFormChange] = useMeetingForm(meeting);

    const onCreateClick = () => {
        onCreate(form);
    };

    useEffect(() => {
        form.config.name.touched = false;
        form.config.date.touched = false;
        form.config.time.touched = false;
        form.set({...meeting});
    }, [meeting]);


    return (
        <>
            <div>
                <h2 className="user-modal user-modal__title">
                    {meeting?.id ? 'Bewerken vergadering' : 'Nieuwe vergadering'}
                </h2>

                <Input
                    id={'name'}
                    text="Naam"
                    size="big"
                    form={form}
                    onChange={onFormChange}/>
                {(form.errors.name && form.errors.name.length > 0) &&
                    <div
                        className="user-modal user-modal__error">{parseError('naam', form.errors.name)?.join(', ')}</div>}
                <Input
                    type={'date'}
                    id={'date'}
                    text="Datum"
                    size="big"
                    form={form}
                    onChange={onFormChange}/>
                {(form.errors.date && form.errors.date.length > 0) &&
                    <div
                        className="user-modal user-modal__error">{parseError('datum', form.errors.date)?.join(', ')}</div>}
                <Input
                    type={'time'}
                    id={'time'}
                    text="Tijd"
                    size="big"
                    form={form}
                    onChange={onFormChange}/>

                {(form.errors.time && form.errors.time.length > 0) &&
                    <div
                        className="user-modal user-modal__error">{parseError('tijd', form.errors.time)?.join(', ')}</div>}


                <TextArea className={'textarea-resize-vertical'} text={'Opmerking'} id={'comment'} form={form} onChange={onFormChange}
                    placeholder={'Geen opmerking'}/>

                {(form.errors.comment && form.errors.comment.length > 0) &&
                    <div
                        className="user-modal user-modal__error">{parseError('opmerking', form.errors.comment)?.join(', ')}</div>}

                <div className="user-modal user-modal__button-group">
                    <CancelButton onClick={onClose}>Annuleren</CancelButton>
                    {meeting?.id ?
                        <Button onClick={() => onUpdate(meeting.id!, form)} disabled={!form.isValid}>Bewerken</Button> :
                        <Button onClick={() => onCreateClick()} disabled={!form.isValid}>Aanmaken</Button>
                    }
                </div>
            </div>
        </>
    );
};
