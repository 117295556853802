import {createRoute, Outlet, redirect} from '@tanstack/react-router';
import React from 'react';
import NavBar from '../components/layout/navbar/NavBar';
import AdminSidebarMenu from '../components/admin/layout/AdminSidebarMenu';
import Content from '../components/layout/Content';
import {rootRoute} from './Router';
import {DashboardRoute} from '../components/client/pages/dashboard/Dashboard';
import {LandingRoute} from '../components/client/pages/auth/Landing';

const AdminLayout = () => {
    return (
        <>
            <NavBar/>
            <AdminSidebarMenu/>

            <Content>
                <Outlet/>
            </Content>
        </>
    );
};

export const AdminRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/admin',
    component: () => <AdminLayout/>,
    beforeLoad: ({context}) => {
        if (context.auth.isAuthenticated() && !context.auth.isSuperAdmin()) {
            throw redirect({to: DashboardRoute.fullPath});
        } else if (!context.auth.isAuthenticated()) {
            throw redirect({to: LandingRoute.fullPath});
        }
    }
});