import React, { FunctionComponent } from 'react';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    children: any;
    onClick?: Function;
    type?: any,
    className?: string,
    del?: any;
    disabled?: any;
    bold?: any;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

const Button: FunctionComponent<Props> = ({ onMouseEnter, onMouseLeave, children, onClick, del, className, disabled, bold, type }) => {
    const { theme } = useTheme();

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(disabled) return;

        if(!onClick) return; 
        
        onClick(e);
    };

    const getClassName = () => {
        const buttonClass = del ? 'button-delete' : 'button-basic';

        return `button ${className ?? ''} 
        ${buttonClass} ${buttonClass}--${theme.modifier} 
        ${disabled && `${buttonClass}--disabled`}
        ${bold && 'button--bold'}`;
    };

    return (
        <button className={getClassName()} onClick={onButtonClick} type={type} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            { children }
        </button>
    );
};

export default Button;
