import React, {FunctionComponent} from 'react';
import {useQuery} from '@tanstack/react-query';

import Page from 'components/layout/Page';
import CenterSettingsContent from './CenterSettingsContent';
import {UserQueryResult, useUserRoleAdminQueryConfig} from '../../../query/User/Query';
import {useAuth} from '../../../contexts/AuthContext';
import {FormQueryPaginationResult, useFormFinalQuery, useFormForCenterQueryConfig} from '../../../query/Form/Form';
import {CenterDetailQueryResult, useCenterDetailQueryConfig} from '../../../query/Center/Query';

interface Props {
    center?: number;
}

const CenterSettings: FunctionComponent<Props> = ({ center }) => {
    const {user} = useAuth();

    const centerId = center ?? user?.center!; // TODO: Make center not undefined

    const [centerConfig] = useCenterDetailQueryConfig(centerId);
    const [centerFormConfig] = useFormForCenterQueryConfig(centerId);
    const [adminConfig] = useUserRoleAdminQueryConfig(centerId);

    const query: [CenterDetailQueryResult, FormQueryPaginationResult, FormQueryPaginationResult, UserQueryResult] = [
        useQuery(centerConfig),
        useFormFinalQuery(),
        useQuery(centerFormConfig),
        useQuery(adminConfig)
    ];

    return (
        <Page scrollable className={'settings-page settings-page__content'}>
            { query.every(q => q.data) &&
                <CenterSettingsContent
                    center={query[0].data![0]}
                    forms={query[1].data!}
                    centerForms={query[2].data!}
                    centerAdmins={query[3].data!}
                />
            }
        </Page>
    );
};

export default CenterSettings;
