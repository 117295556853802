import React, {FunctionComponent, useState} from 'react';
import {TabContent,} from 'buro-lib-ts';
import {useQuery} from '@tanstack/react-query';

import Center from 'networking/models/Center';

import Page from 'components/layout/Page';
import Tabs from 'components/layout/tabs/Tabs';
import CenterList from 'components/client/partials/centers/CenterList';
import SearchInput from 'components/client/partials/input/SearchInput';
import Modal, {useModal} from 'components/client/partials/modal/Modal';
import Button from 'components/layout/buttons/Button';
import CancelButton from 'components/layout/buttons/CancelButton';

import MailIcon from 'assets/icons/message.svg';
import PhoneIcon from 'assets/icons/phone.svg';

import {success, warn} from 'helpers/Toast';

import Input from '../../../client/partials/input/Input';
import {logError} from '../../../utils/devtool/DevTool';
import {useCenterForm} from '../../../form/Center';
import {useCenterIndexQueryConfig,} from '../../../query/Center/Query';
import {useCenterCreateMutation} from '../../../query/Center/Mutation';
import {createRoute, useNavigate} from '@tanstack/react-router';
import {AdminRoute} from '../../../../routing/Admin';
import {CenterDetailPageRoute} from './CenterDetailPage';


export const CentersPageRoute = createRoute({
    getParentRoute: () => AdminRoute,
    component: () => <CentersPage />,
    path: '/'
});

// TODO: Test works, add validation test center creation
const CentersPage: FunctionComponent = () => {
    const navigate = useNavigate();

    const [modalOpen, modalActive, toggleModal] = useModal();
    const [centerForm, onCenterFormChange] = useCenterForm();// TODO: Remove and use component?
    const [searchQuery, setSearchQuery] = useState<string>('');

    const [centerConfig] = useCenterIndexQueryConfig();
    const query = useQuery(centerConfig);

    const createCenter = useCenterCreateMutation();

    const getFilteredCenters = () => {
        if(!query.data)
            return [];

        if(searchQuery === '') {
            return query.data;
        }

        return query.data.filter((c) => c.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1);
    };

    const onCenterClick = (center: Center) => {
        navigate({to: CenterDetailPageRoute.fullPath, params: {centerId: center.id}});
    };

    const closeModal = () => {
        toggleModal();
    };

    const onCenterCreate = async () => {
        createCenter.mutateAsync(centerForm.values).then(() => {
            closeModal();
            success('Wijzigingen opgeslagen.');
        }).catch((e: any) => {
            logError(e);
            warn('Er is een fout opgetreden.');
        });
    };

    return (
        <Page>
            <Tabs noErrors>
                <TabContent>
                    <h2 className={'page__title'}>Meldkameroverzicht</h2>

                    <div className={'page__search flex flex--justify-between'}>
                        <SearchInput onSearch={setSearchQuery} value={searchQuery} />

                        <Button onClick={toggleModal}>Meldkamer aanmaken</Button>
                    </div>

                    <CenterList centers={getFilteredCenters()} onCenterClick={onCenterClick} />
                </TabContent>
            </Tabs>

            <Modal isOpen={modalOpen} active={modalActive} onCloseClick={closeModal} className={'admin-forms-page__modal'}>
                <h3>Nieuwe meldkamer</h3>

                <form className='settings-page__form'>
                    <div className='form-row'>
                        <Input
                            id={'name'}
                            text={'Naam meldkamer'}
                            form={centerForm}
                            onChange={onCenterFormChange} />
                    </div>
                    <div className='form-row'>
                        <Input
                            id={'street'}
                            text={'Straatnaam'}
                            form={centerForm}
                            onChange={onCenterFormChange} />

                        <Input
                            id={'housenumber'}
                            text={'Huisnummer + toevoeging'}
                            form={centerForm}
                            onChange={onCenterFormChange} />

                        <Input
                            id={'postal_code'}
                            text={'Postcode'}
                            form={centerForm}
                            onChange={onCenterFormChange} />

                        <Input
                            id={'city'}
                            text={'Plaatsnaam'}
                            form={centerForm}
                            onChange={onCenterFormChange} />
                    </div>
                    <div className='form-row'>
                        <Input
                            id={'email'}
                            icon={MailIcon}
                            text={'E-mail'}
                            form={centerForm}
                            onChange={onCenterFormChange} />

                        <Input
                            id={'phonenumber'}
                            icon={PhoneIcon}
                            text={'Telefoonnummer'}
                            form={centerForm}
                            onChange={onCenterFormChange} />
                    </div>
                </form>

                <p>Upload het logo van de meldkamer via SFTP naar <em>public/images/centers/id.extensie</em></p>

                <div className={'admin-forms-page__modal-actions'}>
                    <CancelButton onClick={closeModal}>Annuleren</CancelButton>

                    <Button onClick={onCenterCreate}>Aanmaken</Button>
                </div>
            </Modal>
        </Page>
    );
};

export default CentersPage;
