import AuthHeader from '../components/client/pages/auth/layout/AuthHeader';
import {createRoute, Outlet, redirect} from '@tanstack/react-router';
import {rootRoute} from './Router';
import {CentersPageRoute} from '../components/admin/pages/centers/CentersPage';
import {DashboardRoute} from '../components/client/pages/dashboard/Dashboard';
import moment from 'moment';
import React from 'react';

const AuthLayout = () => {
    return (
        <div className="auth">
            <AuthHeader/>
            <Outlet/>
            <p className="auth__footer">Copyright {moment().format('Y')} - Alle rechten voorbehouden</p>
        </div>
    );
};

export const GuestRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: 'auth',
    component: () => <AuthLayout/>,
    beforeLoad: ({context}) => {
        if (context.auth.isAuthenticated()) {
            if (context.auth.isSuperAdmin()) {
                throw redirect({to: CentersPageRoute.fullPath});

            } else {
                throw redirect({to: DashboardRoute.fullPath});
            }
        }
    }
});