import React, { FunctionComponent, useState } from 'react';
import { input, useForm } from 'buro-lib-ts';

import Page from 'components/layout/Page';
import Card from 'components/layout/Card';
import TextArea from 'components/client/partials/input/TextArea';
import Button from 'components/layout/buttons/Button';
import UserFeedbackRepository from 'networking/repos/UserFeedbackRepository';
import { success, warn } from 'helpers/Toast';
import Input from 'components/client/partials/input/Input';
import {createRoute} from '@tanstack/react-router';
import {UserRoute} from '../../../../routing/User';

export const ReportFeedbackRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/feedback',
    component: () => <ReportFeedback />,
});

const ReportFeedback: FunctionComponent = () => {
    const repo = new UserFeedbackRepository();

    const [loading, setLoading] = useState(false);

    const [form, onFormChange] = useForm<{ title: string, feedback: string }>({
        title: input(''),
        feedback: input('')
    });

    const sendFeedback = () => {
        setLoading(true);

        repo.create({
            ...form.values,
            type: 'bug'
        }).then(() => {
            form.reset();
            success('Bug is gemeld! Wij gaan er mee aan de slag.');
        }).catch(() => {
            warn('Er is iets mis gegaan.');
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Page className='report-feedback-page'>
            <Card>
                <h1>Bug melden</h1>

                <Input id={'title'} form={form} onChange={onFormChange} text='Titel' />

                <p>Beschrijf hieronder op welke pagina u zich bevond, welke stappen u uitvoerde indien van toepassing en wat er fout ging.</p>
                <TextArea id={'feedback'} className={'textarea-resize-vertical'} form={form} onChange={onFormChange} />

                <Button onClick={sendFeedback} disabled={form.values.feedback === '' || loading}>Verzenden</Button>
            </Card>
        </Page>
    );
};

export default ReportFeedback;