import React, {FunctionComponent} from 'react';

interface Props {
  text: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
}

const Checkbox: FunctionComponent<Props> = ({ text, checked, onMouseOver, onMouseLeave, onChange, disabled}) => {

    const handleClick = () => {
        if (disabled) {
            return;
        }

        onChange(!checked);
    };

    return (
        <div className="checkbox" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <label className="checkbox checkbox__label">
                {text}
                <input
                    className={`checkbox checkbox__box${disabled ? ' checkbox__box--disabled' : ''}`}
                    type="checkbox"
                    checked={checked}
                    onChange={() => null}
                    onClick={handleClick}
                    disabled={disabled}
                />
                <span className="checkbox checkbox__checkmark" />
            </label>
        </div>
    );
};

export default Checkbox;
