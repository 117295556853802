import Page from '../../../layout/Page';
import Tabs from '../../../layout/tabs/Tabs';
import TabBar from '../../../layout/tabs/TabBar';
import Tab from '../../../layout/tabs/Tab';
import {TabContent} from 'buro-lib-ts';
import UsersTab from '../../pages/users/UsersTab';
import React, {FunctionComponent} from 'react';
import User from '../../../../networking/models/User';
import {UserForm} from '../../../form/User';
import {useTheme} from '../../../contexts/ThemeContext';

interface TabState {
    searchQuery: string;
    title: string;
    filter?: (value: User, index: number, array: User[]) => boolean;
}

interface Props {
    tabs: TabState[];
    setTabs: (tabs: TabState[]) => void;
    users: User[];
    onUserSoftDelete: (user: User) => Promise<void>;
    onUserCreate: (user: UserForm, roles: string[]) => Promise<void>;
    onUserUpdate: (user: User, data: UserForm, roles: string[]) => Promise<void> | void;
    onUserRestore: (user: User) => Promise<void>;
}

const UserTabs: FunctionComponent<Props> = ({tabs, setTabs, users, onUserSoftDelete, onUserCreate, onUserRestore, onUserUpdate}) => {

    const { theme } = useTheme();

    const searchUsers = (users: User[], query: string): User[] => {
        return users.filter((user) => (`${user.first_name}${user.last_name}`)
            .toLowerCase().indexOf(query.toLowerCase()) >= 0);
    };

    const onTabSearch = (tab: number, query: string) => {
        const newTabs = [...tabs];
        newTabs[tab].searchQuery = query;

        setTabs(newTabs);
    };

    return (
        <Page className={`users-page users-page--${theme.modifier}`}>
            <Tabs scrollable>
                <TabBar>
                    {tabs.map((tab, index) => (
                        <Tab key={index}>{tab.title}</Tab>
                    ))}
                </TabBar>

                {tabs.map((tab, index) => {
                    let tabUsers = tab.filter ? users.filter(tab.filter) : users;

                    if (tab.searchQuery !== '') {
                        tabUsers = searchUsers(tabUsers, tab.searchQuery);
                    }

                    return (
                        <TabContent key={index}>
                            <UsersTab
                                tabId={index}
                                title={tab.title}
                                users={tabUsers}
                                searchQuery={tab.searchQuery}
                                onSearch={onTabSearch}
                                onUserSoftDelete={onUserSoftDelete}
                                onUserCreate={onUserCreate}
                                onUserUpdate={onUserUpdate}
                                onUserRestore={onUserRestore}
                            />
                        </TabContent>
                    );
                })}
            </Tabs>
        </Page>
    );
};

export default UserTabs;
