import React, { FunctionComponent } from 'react';

import { useSideBar } from 'components/contexts/SideBarContext';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    children?: any;
}

const SidebarMenu: FunctionComponent<Props> = ({ children }) => {
    const { theme } = useTheme();
    const { menuOpen, toggleMenu } = useSideBar();

    const handleClick = () => {
        toggleMenu();
    };

    const className = `sidebar-menu sidebar-menu--${theme.modifier} sidebar-menu--` + (menuOpen ? 'open' : 'closed');

    return (
        <aside className={className} onClick={handleClick}>
            { children }
        </aside>
    );
};

export default SidebarMenu;


