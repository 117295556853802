import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {useAuditTypeAuditorQueryConfig} from '../../../query/Audit/Query';
import MyAudits from './MyAudits';
import {createRoute, redirect} from '@tanstack/react-router';
import {DashboardRoute} from '../dashboard/Dashboard';
import {UserRoute} from '../../../../routing/User';

export const AuditsForMeRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/tasks',
    component: () => <AuditsForMe />,
    beforeLoad: ({context}) => {
        if (!context.auth.isAuditor()) {
            throw redirect({to: DashboardRoute.fullPath});
        }
    }
});

const AuditsForMe = () => {

    const [auditConfig, page, setPage] = useAuditTypeAuditorQueryConfig({});
    const auditsQuery = useQuery(auditConfig);

    return (
        <>
            {
                auditsQuery &&
                <MyAudits title={'Voor mij'} audits={auditsQuery.data?.data ?? []} total={auditsQuery.data?.total ?? 0}
                    paginationData={{
                        current: auditsQuery.data?.currentPage ?? 1,
                        max: auditsQuery.data?.lastPage ?? 1,
                        setPage: setPage
                    }}/>
            }
        </>
    );
};

export default AuditsForMe;
