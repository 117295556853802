import React, {useState, ChangeEvent} from 'react';
import IconInput from 'components/client/partials/input/IconInput';
import Button from 'components/layout/buttons/Button';
import AuthRepository from '../../../../../networking/repos/AuthRepository';
import { ReactComponent as Message } from '../../../../../assets/icons/message.svg';
import { ReactComponent as Arrow } from '../../../../../assets/icons/arrow.svg';
import Page from 'components/layout/Page';
import {logError} from '../../../../utils/devtool/DevTool';
import CircleLoader from 'components/client/partials/loading/CircleLoader';
import {createRoute, Link, useSearch} from '@tanstack/react-router';
import {LoginRoute} from '../Login';
import {GuestRoute} from '../../../../../routing/Auth';

interface Message {
    success: boolean;
    message: string;
}

export const PasswordResetRoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/password-reset',
    component: () => <PasswordReset />,
});

const PasswordReset = () => {
    const {email} = useSearch({strict: false}) as {email?: string};

    const [emailValue, setEmailValue] = useState<string>(email || '');
    const [message, setMessage] = useState<Message>({} as Message);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailValue(e.target.value);
    };

    const handleSubmit = async () => {
        const authRepository = new AuthRepository();

        const decodedEmail = decodeURIComponent(emailValue);

        setLoading(true);

        try {
            await authRepository.forgotPassword({'email': decodedEmail});
            setLoading(false);
            setMessage({'success': true, 'message': 'Volg de instructies in de zojuist naar u verzonden e-mail.'});
        } catch (requestError: any) {
            setLoading(false);
            logError(requestError);
            setMessage({'success': false, 'message': (requestError.code === 422) ? 'Dit is geen e-mailadres.' : requestError.message});
        }
    };

    return (
        <Page className="auth__page password-reset">
            <div className="auth__page__form">
                <Link to={LoginRoute.fullPath} className="auth__page__form__back">
                    <Arrow />
                    <p>Terug</p>
                </Link>

                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title">Wachtwoord vergeten</h1>
                </div>

                <div className="auth__page__form__row">
                    <p>
                        Instructies om je wachtwoord te resetten worden naar onderstaand e-mailadres gestuurd.
                        <br/> <br/>
                        E-mailadres vergeten? Neem dan contact op met de beheerder van je meldkamer.
                    </p>
                </div>

                <IconInput
                    id="email"
                    value={emailValue}
                    onChange={handleChange}
                    icon={Message}
                    label={true}
                    labelName="E-mail"
                    password={false} />

                {message.message && <p className={message.success ? 'auth__page__form__error auth__page__form__error--success' : 'auth__page__form__error'}>*{message.message}</p>}

                <div className="auth__page__form__buttons">
                    <Link to={LoginRoute.fullPath} className="auth__page__form__buttons__button auth__page__form__buttons__button--cancel">Annuleren</Link>
                    <Button onClick={handleSubmit} className="auth__page__form__buttons__button">Verstuur</Button>
                </div>

                {loading && <CircleLoader center={true}/>}
            </div>
        </Page>
    );
};

export default PasswordReset;
