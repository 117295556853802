import React from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import Page from '../../../../layout/Page';
import {useAuditDetailQueryConfig} from '../../../../query/Audit/Query';
import {
    useAuditCallAudioFileBufferQueryConfig,
    useAuditCallDetailQueryConfig,
    useAuditCallNtsUrlQueryConfig,
    useAuditCallScoresQueryConfig
} from '../../../../query/AuditCall/Query';
import ConversationFormContent from './ConversationFormContent';
import {useFormEntranceComplaintsQueryConfig} from '../../../../query/Form/Form';
import Audit from '../../../../../networking/models/Audit';
import AuditCall from '../../../../../networking/models/AuditCall';
import Score from '../../../../../networking/models/Score';
import {EntranceComplaints} from '../../../../../networking/repos/FormRepository';
import {createRoute, useParams} from '@tanstack/react-router';
import {AuditsRoute} from '../../audits/Audits';

export const ConversationFormRoute = createRoute({
    getParentRoute: () => AuditsRoute,
    path: '/$auditId/conversations/$auditCallId',
    component: () => <ConversationForm />,
});

const ConversationForm = () => {
    const {auditId, auditCallId} = useParams({strict: false}) as {auditId: number, auditCallId: number};

    const [auditConfig] = useAuditDetailQueryConfig(auditId);
    const [auditCallConfig] = useAuditCallDetailQueryConfig(auditId, auditCallId);
    const [auditCallScoresConfig] = useAuditCallScoresQueryConfig(auditId, auditCallId);
    const [formEntranceComplaintsConfig] = useFormEntranceComplaintsQueryConfig();
    const [auditCallNtsUrlQueryConfig] = useAuditCallNtsUrlQueryConfig(auditId, auditCallId);
    const [auditCallAudioBufferQueryConfig] = useAuditCallAudioFileBufferQueryConfig(auditId, auditCallId);

    const audioBufferQuery = useQuery(auditCallAudioBufferQueryConfig);

    const query: [
        UseQueryResult<Audit>,
        UseQueryResult<AuditCall>,
        UseQueryResult<Score[]>,
        UseQueryResult<EntranceComplaints>,
        UseQueryResult<string>,
    ] = [
        useQuery(auditConfig),
        useQuery(auditCallConfig),
        useQuery(auditCallScoresConfig),
        useQuery(formEntranceComplaintsConfig),
        useQuery(auditCallNtsUrlQueryConfig),
    ];


    return (
        <Page className={'conversation-form-page'}>
            { query.every(q => q.data) &&
                <ConversationFormContent
                    audit={query[0].data!}
                    auditCall={query[1].data!}
                    scores={query[2].data!}
                    entranceComplaints={query[3].data!}
                    ntsFormUrl={query[4].data!}
                    audioFileBuffer={audioBufferQuery.data} />
            }
        </Page>
    );
};

export default ConversationForm;
