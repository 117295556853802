import React, {FunctionComponent, Suspense} from 'react';
import {TabContent} from 'buro-lib-ts';

import Page from '../../../layout/Page';
import Tabs from '../../../layout/tabs/Tabs';

import LogsList from '../../partials/events/EventsList';

import PaginationComponent from 'components/layout/pagination/Pagination';

import {useQuery} from '@tanstack/react-query';
import {useEventIndexQueryConfig} from '../../query/Observer';
import {createRoute} from '@tanstack/react-router';
import {AdminRoute} from '../../../../routing/Admin';

export const EventsPageRoute = createRoute({
    getParentRoute: () => AdminRoute,
    path: 'events',
    component: () => <EventsPage />
});
// TODO: Test
const EventsPage: FunctionComponent = () => {
    const [eventConfig, page, setPage] = useEventIndexQueryConfig();
    const eventQuery = useQuery(eventConfig);

    return (
        <Page scrollable>
            <Suspense fallback={<p>TODO:</p>}>
                { eventQuery.data &&
                    <Tabs noErrors>
                        <TabContent>
                            <h2 className={'page__title'}>Logs</h2>
                            <LogsList logs={eventQuery.data.data} />

                            <PaginationComponent
                                current={eventQuery.data.currentPage}
                                max={eventQuery.data.lastPage}
                                setPage={setPage}/>
                        </TabContent>
                    </Tabs>
                }
            </Suspense>
        </Page>
    );
};

export default EventsPage;
