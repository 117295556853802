import React, { FunctionComponent } from 'react';

interface Props {
  active: boolean;
}

const ProgressItem: FunctionComponent<Props> = ({ active }) => {
    return (
        <div className={'auth__registar__progress__bar' + ((active) ? ' auth__registar__progress__bar--active' : '')} />
    );
};

export default ProgressItem;
