import React, {FunctionComponent} from 'react';
import Page from '../../../../layout/Page';
import {useAuditTypeOperatorQueryConfig} from '../../../../query/Audit/Query';
import {useQuery} from '@tanstack/react-query';
import UserAuditsContent from './UserAuditsContent';
import {useUserShowQuery} from '../../../../query/User/Query';
import {useAuth} from '../../../../contexts/AuthContext';
import {createRoute, useParams} from '@tanstack/react-router';
import {UserRoute} from '../../../../../routing/User';

export const UserAuditsRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/users/$userId/audits',
    component: () => <UserAudits />,
});

const UserAudits: FunctionComponent = () => {
    const { user } = useAuth();
    const { userId } = useParams({strict: false}) as {userId: number};

    const [auditConfig] = useAuditTypeOperatorQueryConfig({
        operatorId: userId,
    });

    const auditQuery = useQuery(auditConfig);
    const userQuery = useUserShowQuery(user!.center, userId);
    return (
        <Page className={'user-audits-page'}>
            { (auditQuery.data && userQuery.data) &&
                <UserAuditsContent
                    user={userQuery.data!}
                    audits={auditQuery.data.data} />
            }
        </Page>
    );
};

export default UserAudits;
