import React, {FunctionComponent} from 'react';
import Page from 'components/layout/Page';
import ScoresPerWeightingContent from './ScoresPerWeightingContent';
import {useFormCenterFormsQueryConfig} from '../../../query/Form/Form';
import {useAuth} from '../../../contexts/AuthContext';
import {useUserIndexQueryConfig} from '../../../query/User/Query';
import {useQuery} from '@tanstack/react-query';
import {createRoute} from '@tanstack/react-router';
import {UserRoute} from '../../../../routing/User';

export const StatisticsRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/statistics',
    component: () => <Statistics />,
});

const Statistics: FunctionComponent = () => {
    const { user } = useAuth();

    const [centerFormsConfig] = useFormCenterFormsQueryConfig(user!.center);
    const [userConfig] = useUserIndexQueryConfig(user!.center);

    const centerFormsQuery = useQuery(centerFormsConfig);
    const usersQuery = useQuery(userConfig);

    return (
        <Page className="statistics-page">
            { (centerFormsQuery.data && usersQuery.data) &&
                <ScoresPerWeightingContent
                    forms={centerFormsQuery.data}
                    users={usersQuery.data} />
            }
        </Page>
    );
};

export default Statistics;
