import React, {FunctionComponent, useEffect, useState} from 'react';
import {FormAccessor, OnFormChangeCallback} from 'buro-lib-ts';
import {UserForm} from '../../../form/User';
import User from '../../../../networking/models/User';
import Modal, {useModal} from '../modal/Modal';
import {useAuth} from '../../../contexts/AuthContext';
import {toggleArrayValue} from '../../../../helpers/Utils';
import AuthRepository from '../../../../networking/repos/AuthRepository';
import {success, warn} from '../../../../helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import EditUser from './EditUser';
import CancelButton from '../../../layout/buttons/CancelButton';
import Button from '../../../layout/buttons/Button';
import Checkbox from '../input/Checkbox';
import Tooltip from '../../../layout/utils/Tooltip';

interface Props {
    closeModal: () => void;
    form: FormAccessor<UserForm>;
    onFormChange: OnFormChangeCallback<UserForm>;
    onSaveClick: (form: FormAccessor<UserForm>, roles: string[]) => void;
    user?: User;
}

const UserModal: FunctionComponent<Props> = ({closeModal, form, onFormChange, onSaveClick, user}) => {

    const [rolesInString, setRolesInString] = useState<string[]>([]);

    useEffect(() => {
        if (user) {
            setRolesInString(user.roles.map(role => role.role));
            form.set(user);
            return;
        }
        setRolesInString([]);
        form.reset();
    }, [user]);

    const [modalResetOpen, modalResetActive, toggleResetModal] = useModal();

    const [showAuditTooltip, setShowAuditTooltip] = useState(false);
    const [showBeheerderTooltip, setShowBeheerderTooltip] = useState(false);

    const {isCurrentUser, isSuperAdmin} = useAuth();

    const rolePresent = (role: string): boolean => {
        return rolesInString.includes(role);
    };

    const onRoleChange = (role: string) => {
        const newRoles = [...rolesInString];

        setRolesInString(toggleArrayValue(newRoles, role));
    };

    const onResetClick = async () => {
        if (user === undefined) return;
        const authRepository = new AuthRepository();

        try {
            await authRepository.resetCredentials(user.id!);
            toggleResetModal();
            success('E-Mail verzonden.');
        } catch (e) {
            logError(e);
            warn('Oeps, er is iets misgegaan!');
        }
    };

    const onSave = (form: FormAccessor<UserForm>) => {
        onSaveClick(form, rolesInString);
        setRolesInString([]);
    };

    return (
        <EditUser
            title={`Medewerker ${user ? 'bewerken' : 'toevoegen'}`}
            saveLabel={user ? 'Bewerken' : 'Toevoegen'}
            closeModal={closeModal}
            form={form}
            onFormChange={onFormChange}
            onSaveClick={onSave}>

            {
                user?.id &&
                <>
                    <p className="user-modal user-modal__link" onClick={toggleResetModal}>Inloggegevens opnieuw
                        instellen</p>

                    <Modal isOpen={modalResetOpen} active={modalResetActive} onCloseClick={toggleResetModal}>
                        <h2 className="user-modal user-modal__title">Inloggegevens opnieuw instellen</h2>
                        <p>Wanneer u op onderstaande knopt klikt worden de huidige inloggegevens van deze medewerker
                            ongeldig. <br/> De medewerker ontvangt een e-mail op het ingevulde e-mailadres met daarin
                            instructies voor het opnieuw instellen van het wachtwoord en Google Authenticator.</p>

                        <div className="user-modal user-modal__button-group">
                            <CancelButton onClick={toggleResetModal}>Annuleren</CancelButton>
                            <Button onClick={onResetClick}>Opnieuw instellen</Button>
                        </div>
                    </Modal>
                </>
            }

            {
                !isSuperAdmin() &&
                <div className="user-modal user-modal__checkboxes">
                    <p className="user-modal user-modal__label">Kies functie (Meerdere mogelijk)</p>
                    <div className="user-modal user-modal__container">
                        <div className="user-modal user-modal__row">
                            <Checkbox text="Auditor"
                                onChange={() => onRoleChange('auditor')}
                                checked={rolePresent('auditor')}
                                onMouseOver={() => {
                                    setShowAuditTooltip(true);
                                }}
                                onMouseLeave={() => {
                                    setShowAuditTooltip(false);
                                }}/>

                            <Tooltip className="auditor-tooltip" show={showAuditTooltip}>Als auditor kan de gebruiker
                                audits toetsen.</Tooltip>

                            <Checkbox
                                text="Medisch Specialist"
                                onChange={() => onRoleChange('coach')}
                                checked={rolePresent('coach')}/>
                        </div>

                        <div className="user-modal user-modal__row">
                            <Checkbox
                                text="Triagist"
                                checked={rolePresent('operator')}
                                onChange={() => onRoleChange('operator')}/>

                            <Checkbox text="Beheerder"
                                checked={rolePresent('centerAdmin')}
                                onMouseOver={() => {
                                    setShowBeheerderTooltip(true);
                                }}
                                onMouseLeave={() => {
                                    setShowBeheerderTooltip(false);
                                }}
                                onChange={() => onRoleChange('centerAdmin')}
                                disabled={isCurrentUser(user?.id)}/>

                            <Tooltip
                                className="admin-tooltip"
                                show={showBeheerderTooltip}>Als beheerder kan de gebruiker andere gebruikers
                                beheren.</Tooltip>
                        </div>
                    </div>
                </div>
            }
        </EditUser>
    );
};

export default UserModal;
