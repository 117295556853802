import React, {FunctionComponent, Suspense} from 'react';
import {TabContent} from 'buro-lib-ts';
import {useQuery} from '@tanstack/react-query';

import Page from '../../../layout/Page';
import Tabs from '../../../layout/tabs/Tabs';

import LoginsList from '../../partials/logins/LoginsList';
import PaginationComponent from 'components/layout/pagination/Pagination';

import {useLoginAttemptIndexQueryConfig} from '../../query/Observer';
import {createRoute} from '@tanstack/react-router';
import {AdminRoute} from '../../../../routing/Admin';

export const LoginsPageRoute = createRoute({
    getParentRoute: () => AdminRoute,
    path: 'logins',
    component: () => <LoginsPage/>
});
// TODO: TEST
const LoginsPage: FunctionComponent = () => {
    const [loginAttemptConfig, page, setPage] = useLoginAttemptIndexQueryConfig();
    const query = useQuery(loginAttemptConfig);

    return (
        <Page scrollable>
            <Suspense fallback={<p>ADD</p>}>
                { query.data &&
                    <Tabs noErrors>
                        <TabContent>
                            <h2 className={'page__title'}>Inlogpogingen</h2>

                            <LoginsList logins={query.data.data}/>

                            <PaginationComponent
                                current={query.data.currentPage}
                                max={query.data.lastPage}
                                setPage={setPage}/>
                        </TabContent>
                    </Tabs>
                }
            </Suspense>
        </Page>
    );
};

export default LoginsPage;
