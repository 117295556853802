import React, {FunctionComponent} from 'react';

import UserModel from 'networking/models/User';
import User from 'networking/models/User';
import UserItem from './UserItem';
import {useTheme} from '../../../contexts/ThemeContext';
import {useAuth} from '../../../contexts/AuthContext';
import {UserAuditsRoute} from '../../pages/users/UserAudits/UserAudits';
import {useNavigate} from '@tanstack/react-router';

interface Props {
    users: UserModel[];
    openModal: (user?: User) => void;
    setSelectedUser: Function
    blockModalToggle: () => void
    restoreModalToggle: () => void
}

const UserList: FunctionComponent<Props> = ({
    users,
    openModal,
    setSelectedUser,
    blockModalToggle,
    restoreModalToggle
}) => {
    const {theme} = useTheme();
    const navigate = useNavigate();
    const {isSuperAdmin} = useAuth();

    const onUserClick = (user: User) => {
        if (isSuperAdmin()) return;
        void navigate({
            to: UserAuditsRoute.fullPath,
            params: {userId: user.id}
        });
    };

    return (
        <div className={`user-list user-list__outside user-list--${theme.modifier}`}>
            <div className="user-list__header user-list__grid">
                <span className="user-list__title">Naam</span>
                <span className="user-list__title">E-Mail</span>
                <span className="user-list__title">Tel. Nummer</span>
                <span className="user-list__title">Functie(s)</span>
                <span className="user-list__title">Toegevoegd op</span>
                <span className="user-list__title">Geactiveerd</span>
                <span className="user-list__title">Actie</span>
            </div>

            <div className="user-list__users">
            {
                    users.map((user) => (
                        <UserItem
                            key={user.id}
                            onClick={onUserClick}
                            user={user}
                            openModal={openModal}
                            setSelectedUser={setSelectedUser}
                            blockModalToggle={blockModalToggle}
                            restoreModalToggle={restoreModalToggle}/>
                    ))
                }
            </div>
        </div>
    );
};

export default UserList;
