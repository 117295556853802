import React from 'react';
import CenterSettings from './CenterSettings';
import {createRoute} from '@tanstack/react-router';
import {UserRoute} from '../../../../routing/User';

export const SettingsRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/settings',
    component: () => <Settings />,
});

const Settings = () => {

    return (
        <CenterSettings />
    );
};

export default Settings;
