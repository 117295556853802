import React, {FunctionComponent} from 'react';
import {useNavigate} from '@tanstack/react-router';

import Audit from '../../../../../networking/models/Audit';
import TabBar from '../../../../layout/tabs/TabBar';
import TabBarNavigation from '../../../../layout/tabs/TabBarNavigation';
import BackButton from '../../../../layout/buttons/BackButton';
import {TabContent} from 'buro-lib-ts';
import Tab from '../../../../layout/tabs/Tab';
import Tabs from '../../../../layout/tabs/Tabs';
import AuditList from '../../../partials/audits/AuditList';
import User from '../../../../../networking/models/User';
import TabBarHeader from '../../../../layout/tabs/TabBarHeader';
import {UsersRoute} from '../Users';

interface Props {
    user: User;
    audits: Audit[];
}

const UserAuditsContent: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();

    return (
        <Tabs scrollable>
            <TabBar>
                <TabBarHeader>
                    <BackButton onClick={() => navigate({to: UsersRoute.fullPath})} />
                </TabBarHeader>

                <TabBarNavigation>
                    <Tab>Audits</Tab>
                </TabBarNavigation>
            </TabBar>

            <TabContent>
                <h2>Audits over { props.user.full_name }</h2>

                { props.audits.length
                    ? <AuditList audits={props.audits} />
                    : <p>Geen audits gevonden.</p>
                }
            </TabContent>
        </Tabs>
    );
};

export default UserAuditsContent;
