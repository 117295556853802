import React, {ReactElement, RefAttributes} from 'react';

import { TextArea as BoilerTextArea, BoilerInputElementProps } from 'buro-lib-ts';
import InputContainer from './InputContainer';

interface Props<T> extends BoilerInputElementProps<T, HTMLTextAreaElement> {
    text?: string;
}

// <textarea
//     className={`input__field default-input__field ${password && 'default-input__field--password'} ${icon && 'default-input__field--icon'}`}
//     placeholder={text}
//     onChange={handleInputChange}
//     onFocus={toggleFocus}
//     onBlur={toggleFocus}
//     onKeyPress={onKeyPress}
//     value={getValue()}
//     onKeyUp={onKeyUp ?? ((e) => e.stopPropagation())}
//     disabled={disabled}
//     ref={ref} />
function TextAreaComponent<T>(props: Props<T>, ref: any) {
    const { text, className } = props;
    return (
        <InputContainer text={text}>
            <BoilerTextArea
                { ...props }
                ref={ref}
                className={`input__field default-input__field ${className ? className : ''}`} />
        </InputContainer>
    );
}

const TextArea = React.forwardRef(TextAreaComponent) as unknown as
    <T>(props: Props<T> & RefAttributes<HTMLTextAreaElement>) => ReactElement;

export default TextArea;
