import React, { FunctionComponent } from 'react';

import WayPoint from 'networking/models/WayPoint';

import WavePointComment from './WavePointComment';
import {useTheme} from '../../../../contexts/ThemeContext';

interface Props {
    wavePoints: WayPoint[];
    onDeleteClick: (wayPoint: WayPoint) => void;
    onWayPointEdit: (wayPoint: WayPoint) => void;
    editable: boolean;
}

const WavePointCommentList: FunctionComponent<Props> = ({ wavePoints, onWayPointEdit, onDeleteClick, editable }) => {
    const { theme } = useTheme();

    return (
        <div className={`wave-point-comment-list wave-point-comment-list--${theme.modifier}`}>
            <div className={'wave-point-comment-list__grid wave-point-comment-list__header'}>
                <span className={'wave-point-comment-list__title'}>#</span>
                <span className={'wave-point-comment-list__title'}>Tijd</span>
                <span className={'wave-point-comment-list__title'}>Opmerking</span>
            </div>

            { wavePoints.map((wavePoint, index) => (
                <WavePointComment
                    wayPoint={wavePoint}
                    key={wavePoint.id ?? wavePoint.index}
                    onEdit={onWayPointEdit}
                    index={index}
                    onDeleteClick={onDeleteClick}
                    editable={editable}
                />
            )) }
        </div>
    );
};

export default WavePointCommentList;
