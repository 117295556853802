import React, {ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent} from 'react';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const StylessButton: FunctionComponent<Props> = (props) => {

    const {className, ...rest} = props;

    const getClassName = (templateClass: string) => {
        return templateClass + (className ? ` ${className}` : '');
    };

    return (
        <button className={getClassName('styless-button')} {...rest}/>
    );
};

export default StylessButton;
