import React from 'react';
import NavBar from '../components/layout/navbar/NavBar';
import ClientSidebarMenu from '../components/client/layout/ClientSidebarMenu';
import Content from '../components/layout/Content';
import {createRoute, Outlet, redirect} from '@tanstack/react-router';
import {rootRoute} from './Router';
import {LandingRoute} from '../components/client/pages/auth/Landing';
import {AdminRoute} from './Admin';

const UserLayout = () => {
    return (
        <>
            <NavBar/>
            <ClientSidebarMenu/>

            <Content>
                <Outlet/>
            </Content>
        </>
    );
};

export const UserRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: 'user',
    component: () => <UserLayout/>,
    beforeLoad: ({context}) => {
        if (context.auth.isSuperAdmin()) {
            throw redirect({to: AdminRoute.fullPath});
        } else if (!context.auth.isAuthenticated()) {
            throw redirect({to: LandingRoute.fullPath});
        }
    }
});