import React, { FunctionComponent } from 'react';

import CheckIcon from '../../../utils/CheckIcon';
import CrossIcon from '../../../utils/CrossIcon';

interface Props {
  label: string;
  passed: boolean;
}

const PasswordCheck: FunctionComponent<Props> = ({ label, passed }) => {
    return (
        <div className="auth__registar__password-checks__check">
            {(passed)? (
                <CheckIcon />
            ) : (
                <CrossIcon />
            )}
            <span>{label}</span>
        </div>
    );
};

export default PasswordCheck;
