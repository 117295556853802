import React, {FunctionComponent} from 'react';
import {input, useForm} from 'buro-lib-ts';
import TextArea from '../../partials/input/TextArea';

interface Form {
    comment: string;
}

interface Props {
    title: string;
    comment: string;
    disabled: boolean;
    onSave: (comment: string) => void;
    placeholder: string;
}

const AuditCommentBox: FunctionComponent<Props> = ({title, comment, disabled, onSave, placeholder}) => {
    const [form, onFormChange] = useForm<Form>({
        comment: input(comment || '')
    });

    const handleSave = () => {
        onSave(form.values.comment);
    };

    return (
        <div className={'comments-box'}>
            <div className={'comments-box__content'}>
                <div className={'comments-box--card'}>
                    <div className={'flex flex--justify-between flex--align-center'}>
                        <h3>{title}</h3>
                    </div>

                    <div className={'comments-box__content'}>
                        <TextArea
                            className={'textarea-resize-vertical'}
                            id={'comment'}
                            text={'(autosave)'}
                            placeholder={placeholder}
                            form={form}
                            onChange={onFormChange}
                            onBlur={handleSave}
                            disabled={disabled} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditCommentBox;
