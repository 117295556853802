import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {useAuditTypeOperatorQueryConfig} from '../../../query/Audit/Query';
import MyAudits from './MyAudits';
import {createRoute, redirect} from '@tanstack/react-router';
import {DashboardRoute} from '../dashboard/Dashboard';
import {UserRoute} from '../../../../routing/User';

export const AuditsAboutMeRoute = createRoute({
    getParentRoute: () => UserRoute,
    path: '/aboutme',
    component: () => <AuditsAboutMe />,
    beforeLoad: ({context}) => {
        if (!context.auth.isOperator()) {
            throw redirect({to: DashboardRoute.fullPath});
        }
    }
});

const AuditsAboutMe = () => {

    const [auditConfig, page, setPage] = useAuditTypeOperatorQueryConfig();
    const auditsQuery = useQuery(auditConfig);

    return (
        <>
            {
                auditsQuery &&
                <MyAudits title={'Over mij'} audits={auditsQuery.data?.data ?? []} total={auditsQuery.data?.total ?? 0}
                    paginationData={{
                        current: auditsQuery.data?.currentPage ?? 1,
                        max: auditsQuery.data?.lastPage ?? 1,
                        setPage: setPage
                    }}/>
            }
        </>
    );
};

export default AuditsAboutMe;
