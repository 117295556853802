import {toast} from "react-toastify"

export const notify = (message: string) => {
    toast(message);
};

export const warn = (message: string) => {
    toast.error(message);
};

export const success = (message: string) => {
    toast.success(message);
}
