import React, { FunctionComponent, useState } from 'react';
import { Link } from '@tanstack/react-router';

interface Props {
    children: any;
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>,
    activeIcon: FunctionComponent<React.SVGProps<SVGSVGElement>>,
    to: string;
}

const SidebarMenuItem: FunctionComponent<Props> = ({ icon, activeIcon, to, children }) => {
    const [active, setActive] = useState<boolean>(false);

    const Icon = active ? activeIcon : icon;


    // TODO: Check exact
    return (
        <Link to={to} className={'sidebar-menu__item'} activeProps={{className: 'sidebar-menu__item--active'}} activeOptions={{exact: true}} onClick={(e) => e.stopPropagation()} >
            <div className={'sidebar-menu__icon'}>
                <Icon className={'sidebar-menu__icon icon-white icon-white--filled'} />
            </div>

            <span className={'sidebar-menu__label'}>{ children }</span>
        </Link>
    );
};

export default SidebarMenuItem;
