import React, {FunctionComponent} from 'react';
import Box from '../../../partials/box/Box';
import BoxHeader from '../../../partials/box/BoxHeader';
import BoxContent from '../../../partials/box/BoxContent';
import Select from '../../../partials/input/select/Select';
import {Line} from 'react-chartjs-2';
import {useFormAvailableYearsQueryConfig} from '../../../../query/Form/Form';
import {useQuery} from '@tanstack/react-query';
import {ScoreFilter} from '../../../../query/Center/Query';
import Button from '../../../../layout/buttons/Button';

interface Props {
    onYearSelect: (selected: any) => void;
    graphLineData: any;
    renderLineGraph: boolean;
    filters: ScoreFilter;
    center: any;
}

const FormScoresBox: FunctionComponent<Props> = ({onYearSelect, graphLineData, renderLineGraph, filters, center}) => {

    const [availableYearsQueryConfig] = useFormAvailableYearsQueryConfig(center, filters);
    const availableYearsQuery = useQuery(availableYearsQueryConfig);

    const [selectedYear, setSelectedYear] = React.useState<any>(null);

    const colorDesired = '#2bda7e';
    const colorNecessary = '#ffb23f';
    const colorRequired = '#d71e00';

    const lineDataObject = {
        labels: graphLineData.x,
        datasets: [
            {
                label: 'Gewenst',
                data: graphLineData.y.scoresD,
                fill: false,
                backgroundColor: colorDesired,
                borderColor: colorDesired,
            },
            {
                label: 'Noodzakelijk',
                data: graphLineData.y.scoresN,
                fill: false,
                backgroundColor: colorNecessary,
                borderColor: colorNecessary,
            },
            {
                label: 'Verplicht',
                data: graphLineData.y.scoresK,
                fill: false,
                backgroundColor: colorRequired,
                borderColor: colorRequired,
            }
        ]
    };

    const graphLineOptions = {
        animation: {
            duration: 0
        },
        plugins: {
            title: {
                display: renderLineGraph,
                text: (renderLineGraph) ? (`Scores voor formulier '${filters.form?.name}' in ${new Date().getFullYear()}`) : (''),
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Maand'
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Procent'
                },
                beginAtZero: true,
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    stepSize: 10
                }
            }
        }
    };

    const generateGraph = () => {
        if (selectedYear) {
            onYearSelect(selectedYear);
        }
    };

    const convertAvailableYears = () => {
        const years: any[] = [];
        for (const year of availableYearsQuery.data?.years ?? []) {
            years.push({
                value: year.toString(),
                render: year.toString()
            });
        }
        return years;
    };

    return (
        <Box>
            <BoxHeader>
                Gemiddelde scores per weging per formulier
            </BoxHeader>
            <BoxContent>
                <div className="statistics-page__line statistics-page__row">
                    <div className="statistics-page__col--left">
                        <div className="statistics-page__filters">
                            <Select
                                placeholder={'Kies jaartal'}
                                options={convertAvailableYears()}
                                onSelect={setSelectedYear}
                                title={'Kies jaartal'}
                                renderOption={(year: any) => year.render}/>
                            <br/>
                            <Button onClick={generateGraph}>Genereer diagram</Button>
                        </div>
                    </div>
                    <div className="statistics-page__col--right">
                        <div className="statistics-page__line">
                            {
                                (renderLineGraph) ? (
                                    <Line data={lineDataObject} options={graphLineOptions}/>
                                ) : (
                                    <div className="statistics-page__placeholder">
                                        <p>Selecteer een jaartal</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </BoxContent>
        </Box>
    );
};

export default FormScoresBox;
