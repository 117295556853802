import React, {ChangeEvent, FormEvent, useState} from 'react';
import {createRoute, useNavigate, useParams} from '@tanstack/react-router';

import PasswordCheck from '../../../partials/auth/PasswordCheck';
import IconInput from 'components/client/partials/input/IconInput';
import Button from 'components/layout/buttons/Button';

import AuthRepository from '../../../../../networking/repos/AuthRepository';

import {ReactComponent as Arrow} from '../../../../../assets/icons/arrow.svg';
import {ReactComponent as KeyPassword} from '../../../../../assets/icons/key-password.svg';

import {success, warn} from '../../../../../helpers/Toast';

import Page from 'components/layout/Page';
import {logError} from '../../../../utils/devtool/DevTool';
import CircleLoader from '../../../partials/loading/CircleLoader';
import {LandingRoute} from '../Landing';
import {GuestRoute} from '../../../../../routing/Auth';

interface PasswordsObject {
    password: string;
    password_confirmation: string;
}

type PasswordRules = {
    contains_uppercase: boolean,
    contains_minchar: boolean,
    contains_special: boolean,
    contains_number: boolean,
    password_match: boolean
}

export const NewPasswordRoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/password-reset/reset/$email/$token',
    component: () => <NewPassword />,
});

const NewPassword = () => {
    const navigate = useNavigate();

    const [passwords, setPasswords] = useState<PasswordsObject>({
        'password': '',
        'password_confirmation': ''
    });

    const {email, token} = useParams({strict: false}) as {email: string, token: string};

    // Store password checks in state
    const [passwordRules, setPasswordRules] = useState<PasswordRules>({
        contains_uppercase: false, contains_minchar: false, contains_special: false,
        contains_number: false, password_match: false
    });

    const [passwordSaveLoading, setPasswordSaveLoading] = useState<boolean>(false);

    // Checks if all password rules are met
    const passwordRulesMatchAll = () => {
        return Object.values(passwordRules).reduce((prev, curr) => prev && curr);
    };

    // Updates passwordRules on change of password
    const handleOnKeyUp = () => {
        const currentRules: PasswordRules = {
            contains_minchar: passwords.password.length >= 8,
            contains_uppercase: passwords.password.toLowerCase() !== passwords.password,
            contains_number: /\d/.test(passwords.password), // Contains at least 1 digit
            contains_special: /.*[\W_].*/.test(passwords.password), // Contains at least 1 special char
            password_match: passwords.password === passwords.password_confirmation
        };

        setPasswordRules(currentRules);
    };

    const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswords({ ...passwords, [e.target.id]: e.target.value});
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const authRepository = new AuthRepository();

        if (passwordRulesMatchAll()) {
            setPasswordSaveLoading(true);
            authRepository.forgotPasswordReset({
                email: email!, token: token!,
                'password': passwords.password, 'password_confirmation': passwords.password_confirmation
            }).then(_ => {
                setPasswordSaveLoading(false);
                success('Wachtwoord is opnieuw ingesteld.');
                navigate({to:LandingRoute.fullPath});
            }).catch(e => {
                setPasswordSaveLoading(false);
                logError(e);
                warn('Er is iets misgegaan.');
            });
        }
    };

    const goBack = () => {
        navigate({to: LandingRoute.fullPath});
    };

    return (
        <Page className="auth__page">
            <div className="auth__page__form">
                <form className="auth__registar__form" onSubmit={handleSubmit}>

                    <div className="auth__page__form__back" onClick={goBack}>
                        <Arrow />
                        <p>Terug</p>
                    </div>

                    <div className="auth__page__form__row">
                        <h1 className="title--black auth__page__form__title">Wachtwoord resetten</h1>
                    </div>

                    <IconInput
                        id="password"
                        value={passwords.password}
                        onChange={handleChangePassword}
                        onKeyUp={handleOnKeyUp}
                        icon={KeyPassword}
                        label={true}
                        labelName="Wachtwoord"
                        password={true} />

                    <div className="auth__registar__password-checks">
                        <div className="auth__registar__password-checks__col">
                            <PasswordCheck label={'Min. 8 karakters'} passed={passwordRules.contains_minchar} />
                            <PasswordCheck label={'Min. 1 hoofdletter'} passed={passwordRules.contains_uppercase} />
                        </div>
                        <div className="auth__registar__password-checks__col">
                            <PasswordCheck label={'Min. 1 cijfer'} passed={passwordRules.contains_number} />
                            <PasswordCheck label={'Min. 1 speciaal teken'} passed={passwordRules.contains_special} />
                        </div>
                    </div>

                    <IconInput
                        id="password_confirmation"
                        value={passwords.password_confirmation}
                        onChange={handleChangePassword}
                        onKeyUp={handleOnKeyUp}
                        icon={KeyPassword}
                        label={true}
                        labelName="Wachtwoord herhalen"
                        password={true} />

                    <div className="auth__registar__password-checks">
                        <PasswordCheck label={'Wachtwoorden komen overeen'} passed={passwordRules.password_match} />
                    </div>

                    <br />
                    <br />
                    <br />

                    <Button disabled={!passwordRulesMatchAll()} className="button--right">Opslaan</Button>
                    {passwordSaveLoading && <CircleLoader center={true}/>}
                </form>
            </div>
        </Page>
    );
};

export default NewPassword;
