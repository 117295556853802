import React, {FunctionComponent} from 'react';
import SidebarMenuItem from '../../layout/sidebar/SidebarMenuItem';
import {ReactComponent as DashboardIcon} from '../../../assets/icons/dashboard-border.svg';
import {ReactComponent as DashboardIconActive} from '../../../assets/icons/dashboard.svg';
import {ReactComponent as AuditIcon} from '../../../assets/icons/audits-border.svg';
import {ReactComponent as AuditIconActive} from '../../../assets/icons/audits.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/icon-user-border.svg';
import {ReactComponent as UserIconActive} from '../../../assets/icons/icon-user.svg';
import {ReactComponent as CenterIcon} from '../../../assets/icons/center-border.svg';
import {ReactComponent as CenterIconActive} from '../../../assets/icons/center.svg';
import {ReactComponent as StatisticsIcon} from '../../../assets/icons/statistics-border.svg';
import {ReactComponent as StatisticsIconActive} from '../../../assets/icons/statistics.svg';
import {ReactComponent as UsersIcon} from '../../../assets/icons/icon-users-border.svg';
import {ReactComponent as UsersIconActive} from '../../../assets/icons/icon-users.svg';
import {ReactComponent as ContactIcon} from '../../../assets/icons/contacts-border.svg';
import {ReactComponent as ContactIconActive} from '../../../assets/icons/contacts.svg';
import {ReactComponent as SettingsIcon} from '../../../assets/icons/settings-border.svg';
import {ReactComponent as SettingsIconActive} from '../../../assets/icons/settings.svg';
import {ReactComponent as BugIcon} from '../../../assets/icons/bug.svg';
import SidebarMenu from '../../layout/sidebar/SidebarMenu';
import {useAuth} from '../../contexts/AuthContext';
import {DashboardRoute} from '../pages/dashboard/Dashboard';
import {AuditsAboutMeRoute} from '../pages/audits/AuditsAboutMe';
import {AuditsForMeRoute} from '../pages/audits/AuditsForMe';
import {AuditsRoute} from '../pages/audits/Audits';
import {StatisticsRoute} from '../pages/statistics/Statistics';
import {UsersRoute} from '../pages/users/Users';
import {CentersRoute} from '../pages/centers/Centers';
import {SettingsRoute} from '../pages/settings/Settings';
import {ReportFeedbackRoute} from '../pages/user_feedback/ReportFeedback';

const ClientSidebarMenu: FunctionComponent = () => {
    const { isCenterAdmin, isAuditor, isOperator, isCoach } = useAuth();

    return (
        <SidebarMenu>
            <SidebarMenuItem icon={DashboardIcon} activeIcon={DashboardIconActive} to={DashboardRoute.fullPath}>Dashboard</SidebarMenuItem>

            { isOperator() &&
                <SidebarMenuItem icon={AuditIcon} activeIcon={AuditIconActive} to={AuditsAboutMeRoute.fullPath}>Over mij</SidebarMenuItem>
            }

            { isAuditor() &&
                <SidebarMenuItem icon={UserIcon} activeIcon={UserIconActive} to={AuditsForMeRoute.fullPath}>Voor mij</SidebarMenuItem>
            }

            {(isCoach() || isCenterAdmin()) &&
                <>
                    <SidebarMenuItem icon={CenterIcon} activeIcon={CenterIconActive} to={AuditsRoute.fullPath} >Meldkamer</SidebarMenuItem>
                    <SidebarMenuItem icon={StatisticsIcon} activeIcon={StatisticsIconActive} to={StatisticsRoute.fullPath}>Statistieken</SidebarMenuItem>

                    { (isCenterAdmin() || isCoach()) && <SidebarMenuItem icon={UsersIcon} activeIcon={UsersIconActive} to={UsersRoute.fullPath}>Medewerkers</SidebarMenuItem> }

                    <SidebarMenuItem icon={ContactIcon} activeIcon={ContactIconActive} to={CentersRoute.fullPath}>Extern contact</SidebarMenuItem>

                    { isCenterAdmin() && <SidebarMenuItem icon={SettingsIcon} activeIcon={SettingsIconActive} to={SettingsRoute.fullPath}>Instellingen</SidebarMenuItem> }
                </>
            }

            <SidebarMenuItem icon={BugIcon} activeIcon={BugIcon} to={ReportFeedbackRoute.fullPath}>Bug melden</SidebarMenuItem>
        </SidebarMenu>
    );
};

export default ClientSidebarMenu;
