import React, {FunctionComponent, useEffect} from 'react';
import {check, input, useForm, V} from 'buro-lib-ts';

import Question from '../../../../networking/models/Question';
import FormPart from '../../../../networking/models/FormPart';
import Input from '../../../client/partials/input/Input';
import CancelButton from '../../../layout/buttons/CancelButton';
import Button from '../../../layout/buttons/Button';
import {notEmpty} from '../../../../helpers/Validation';
import Toggle from '../../../layout/Toggle';
import TextArea from '../../../client/partials/input/TextArea';
import ImageUpload from '../../../utils/ImageUpload';
import UploadArea from '../../../client/partials/audits/UploadArea';
import FormQuestionRepository from '../../../../networking/repos/FormQuestionRepository';

interface QuestionForm {
    question: string;
    explanation: string;
    optional: boolean;
    necessary: boolean;
    knockout: boolean;
}

interface Props {
    formId: number;
    part: FormPart;
    question: Question;
    questionNumber: number;
    onSave: (part: FormPart, question: Question) => void;
    onCancel: () => void;
}

const QuestionModal: FunctionComponent<Props> = ({formId, part, question, questionNumber, onSave, onCancel}) => {
    const [form, onFormChange] = useForm<QuestionForm>({
        question: input('', [V.required]),
        explanation: input('', [V.required]),
        knockout: check(false),
        necessary: check(false),
        optional: check(false)
    });

    useEffect(() => {
        form.set({...question});
    }, [question]);


    const canSave = (): boolean => {
        return notEmpty(form.values, ['question', 'explanation']);
    };

    const save = () => onSave(part, form.values);

    const setQuestionType = (type: string) => {
        const newQuestionState = {...form.values, knockout: false, necessary: false, optional: false};

        if (!Object.hasOwn(newQuestionState, type))
            throw new Error(`Cannot set question type ${type}`);

        form.set({...newQuestionState, [type]: true});
    };

    const setKnockout = () => {
        setQuestionType('knockout');
    };

    const setNecessary = () => {
        setQuestionType('necessary');
    };

    const setOptional = () => {
        setQuestionType('optional');
    };

    const cancel = () => {
        form.set({...question});
        onCancel();
    };

    const onImageUpload = (image: File) => {
        return new Promise<string>((resolve) => {
            const questionRepo = new FormQuestionRepository(formId, question.part_id ?? 0);

            const formData = new FormData();
            formData.append('file', image);
            questionRepo.uploadFile(question.id!, formData).then(r => {

                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(image);

            });

        });
    };

    const onImageDelete = () => {
        return new Promise<void>((resolve) => {
            const questionRepo = new FormQuestionRepository(formId, question.part_id ?? 0);

            questionRepo.deleteFile(question.id!).then(() => {
                resolve();
            });
        });
    };


    return (
        <div className={'question-modal'}>
            <p className={'page__sub-title'}>{part?.title}</p>
            <p className={'question-modal__title'}>{questionNumber > 0 ? `Vraag ${questionNumber}` : 'Nieuwe vraag'}</p>

            <div className={'question-modal__inputs'}>
                <Input
                    className={'question-modal__input'}
                    id={'question'}
                    text={'Vraag'}
                    form={form}
                    onChange={onFormChange}/>

                <TextArea
                    className={'question-modal__input textarea-resize-vertical'}
                    id={'explanation'}
                    text={'Uitleg'}
                    form={form}
                    onChange={onFormChange}/>
                {
                    question.id &&
                    <ImageUpload imagePath={question.image} onUpload={onImageUpload} onDelete={onImageDelete}/>
                }

            </div>

            <div className={'question-modal__options'}>
                <div className={'flex flex--justify-between'}>
                    <span>Gewenst</span>
                    <Toggle id={'optional'} active={form.values.optional ?? false} onChange={setOptional}/>
                </div>

                <div className={'flex flex--justify-between'}>
                    <span>Noodzakelijk</span>
                    <Toggle id={'necessary'} active={form.values.necessary ?? false} onChange={setNecessary}/>
                </div>

                <div className={'flex flex--justify-between'}>
                    <span>Verplicht</span>
                    <Toggle id={'knockout'} active={form.values.knockout ?? false} onChange={setKnockout}/>
                </div>
            </div>

            <div className={'question-modal__actions'}>
                <CancelButton onClick={cancel}>Annuleren</CancelButton>
                <Button onClick={save} disabled={!canSave()}>Opslaan</Button>
            </div>
        </div>
    );
};

export default QuestionModal;
