import React, {FunctionComponent} from 'react';

import {FormAccessor, OnFormChangeCallback} from 'buro-lib-ts';

import Input from 'components/client/partials/input/Input';

import Message from '../../../../assets/icons/message.svg';

import CancelButton from 'components/layout/buttons/CancelButton';
import Button from 'components/layout/buttons/Button';

import {useTheme} from '../../../contexts/ThemeContext';
import {UserForm} from '../../../form/User';
import {parseError} from '../../../../helpers/Validation';

export interface EditUserProps {
    children?: any;
    title: string;
    saveLabel: string;
    closeModal: () => void;
    form: FormAccessor<UserForm>;
    onFormChange: OnFormChangeCallback<UserForm>;
    onSaveClick: (form: FormAccessor<UserForm>) => void;
}

// TODO: Add form validation and remove errors
const EditUser: FunctionComponent<EditUserProps> = (props) => {
    const {title, closeModal, onFormChange, onSaveClick, form, children, saveLabel} = props;

    const {theme} = useTheme();

    const onSave = () => {
        onSaveClick(props.form);
    };

    return (
        <div className={`user-modal ${theme.modifier}`}>
            <h2 className="user-modal user-modal__title">
                {title}
            </h2>

            <Input
                id={'first_name'}
                text="Voornaam"
                size="big"
                form={form}
                onChange={onFormChange}/>
            {(form.errors.first_name && form.errors.first_name.length > 0) && <div
                className="user-modal user-modal__error">{parseError('voornaam', form.errors.first_name)?.join(', ')}</div>}

            <Input
                id={'last_name'}
                text="Achternaam"
                size="big"
                form={form}
                onChange={onFormChange}/>
            {(form.errors.last_name && form.errors.last_name.length > 0) &&
                <div className="user-modal user-modal__error">{parseError('achternaam', form.errors.last_name)?.join(', ')}</div>}

            <Input
                id={'email'}
                text="E-mail"
                size="big"
                icon={Message}
                form={form}
                onChange={onFormChange}/>
            {(form.errors.email && form.errors.email.length > 0) &&
                <div className="user-modal user-modal__error">{parseError('email',form.errors.email)?.join(', ')}</div>}

            <Input
                id={'phone'}
                text="Telefoonnummer"
                size="big"
                form={form}
                onChange={onFormChange}/>
            {(form.errors.phone && form.errors.phone.length > 0) &&
                <div className="user-modal user-modal__error">{parseError('telefoonnummer', form.errors.phone)?.join(', ')}</div>}

            {children}

            <div className="user-modal user-modal__button-group">
                <CancelButton onClick={closeModal}>Annuleren</CancelButton>
                <Button onClick={onSave} disabled={!form.isValid}>{saveLabel}</Button>
            </div>
        </div>
    );
};

export default EditUser;
