import React, {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react';
import {input, useForm} from 'buro-lib-ts';
import Option from './Option';
import Input from '../Input';
import {useTheme} from '../../../../contexts/ThemeContext';

export interface SelectOption {
    value: any;
    id: number;
}

interface Props {
    options: any[];
    onSelect: (selected: any, index: number) => void;
    placeholder?: string;
    initialIndex?: number;
    renderOption?: (option: any) => any;
    title?: string;
    selected?: number;
    selectedId?: number;
    onSearch?: (search: string, options: SelectOption[]) => SelectOption[];
    disabled?: boolean;
}

const Select: FunctionComponent<Props> = ({disabled, selected, selectedId, options, onSelect, placeholder, initialIndex, title, renderOption, onSearch}) => {
    const {theme} = useTheme();

    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<number>(-1);
    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);
    const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

    const config = useMemo(() => {
        return {
            search: input('')
        };
    }, []);

    const [form, onFormChange] = useForm<{ search: string }>(config);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('click', checkClickOutside, true);

        if (selected !== undefined) {
            setSelectedOption(selected);
        } else if (initialIndex !== undefined) {
            setSelectedOption(initialIndex);
        }

        if (selectedId !== undefined) {
            const index = options.findIndex((option) => option.id === selectedId);

            setSelectedOption(index);
        }

        return () => document.removeEventListener('click', checkClickOutside, true);
    }, []);

    useEffect(() => {
        setSelectOptions(options.map((option, index) => ({value: option, id: index})));
    }, [options]);

    useEffect(() => {
        if (!onSearch) return;

        const foundOptions = onSearch(form.values.search, selectOptions);

        if (foundOptions.length === options.length) {
            setFilteredOptions([]);
        } else {
            setFilteredOptions(foundOptions);
        }
    }, [form.values.search]);

    const checkClickOutside = (e: any) => {
        if (!ref.current || ref.current?.contains(e.target)) return;

        setShowOptions(false);
    };

    const toggleOptions = () => !disabled && setShowOptions(!showOptions);

    const selectOption = (index: number) => {
        setSelectedOption(index);
        onSelect(options[index], index);
        setShowOptions(false);
    };

    const getSelectedClassname = (): string => {
        return `
            dropdown__selected
            ${placeholder && selectedOption === undefined ? 'dropdown__custom-select__selected--placeholder' : ''} 
        `;
    };

    const getOptionsClassName = (): string => {
        return `
            dropdown__options
            ${showOptions ? 'dropdown__options--open' : ''}
        `;
    };

    const getSelectedText = () => {
        const option = selected ?? selectedOption;

        if (!options.length || option === undefined) return title;

        if (option === -1) return placeholder ?? title;

        return renderOption
            ? renderOption(options[option])
            : options[option];
    };

    const removeSelection = () => {
        selectOption(-1);
    };

    const filteredSelectOptions = filteredOptions.length ? filteredOptions : selectOptions;

    return (
        <div className={`dropdown dropdown--${theme.modifier} ${disabled ? 'dropdown--disabled' : ''}`} ref={ref}>
            {title &&
                <p className={'dropdown__title'}>{title}
                    {selectedOption !== -1 &&
                        <span className={'dropdown__clear'} onClick={removeSelection}> X</span>
                    }
                </p>
            }

            <div className="dropdown__custom-select">
                <div
                    className={getSelectedClassname()}
                    onClick={toggleOptions}>

                    <p className="dropdown__selected-text">{getSelectedText()}</p>

                    <div className={`dropdown__arrow dropdown__arrow${showOptions ? '--open' : ''}`}/>
                </div>

                <div className={getOptionsClassName()}>
                    {onSearch &&
                        <div className={'dropdown__search-bar'}>
                            <Input text={'Zoeken...'} onChange={onFormChange} form={form} id={'search'}/>
                        </div>
                    }

                    <div className={'dropdown__scroll-area'}>
                        {
                            filteredSelectOptions.map((option) => {
                                const selected = option.id === selectedOption;

                                return (
                                    <Option selected={selected} onSelect={() => selectOption(option.id)}
                                        key={option.id}>
                                        {renderOption
                                            ? renderOption(option.value)
                                            : option.value
                                        }
                                    </Option>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Select;
