import React, { FunctionComponent } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';

import moment from 'moment';
import {Link} from '@tanstack/react-router';
import Conversations, {ConversationsRoute} from '../../conversations/Conversations';

interface AuditData {
    id: number;
    auditor_id: number;
    operator_id: number;
    original_audit_id?: number|null;
    required_calls: number;
    first_name: string;
    last_name: string;
    completed_at?: string|null;
    updated_at: string|null;
    created_at: string;
}

interface Props {
    user: string;
    data: AuditData;
}

const AuditItem: FunctionComponent<Props> = (props) => {
    const created_at = moment(props.data.created_at).format('DD-MM-YYYY');

    const auditFromModel = {
        id: props.data.id,
        auditor_id: props.data.auditor_id,
        original_audit_id: props.data.original_audit_id,
        required_calls: props.data.required_calls,
        completed_at: props.data.completed_at
    };

    return (
        <Link to={ConversationsRoute.fullPath} params={{auditId: auditFromModel.id}} className="dashboard-page__audits__item">
            <div className="dashboard-page__audits__item__column--left">
                <h5 className="heading5--black">{`Audit van ${props.user}`}</h5>
                <table className="dashboard-page__audits__item__table">
                    <tbody>
                        <tr>
                            <th className="smalltekst--black-undercase">Aangemaakt op</th>
                            <td className="smalltekst--tonal-undercase">{created_at}</td>
                        </tr>
                        <tr>
                            <th className="smalltekst--black-undercase">Rol</th>
                            <td className="smalltekst--tonal-undercase">Auditor</td>
                        </tr>
                        <tr>
                            <th className="smalltekst--black-undercase">Triagist</th>
                            <td className="smalltekst--tonal-undercase">{`${props.data.first_name} ${props.data.last_name}`}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="dashboard-page__audits__item__column--right">
                <p className="smalltekst--tonal-undercase">Audit toetsen</p>
                <ArrowIcon className="icon-grey icon-grey--filled dashboard-page__audits__item__arrow"/>
            </div>
        </Link>
    );
};

export default AuditItem;
