import React, {FunctionComponent} from 'react';

import User from 'networking/models/User';

import {useAuth} from 'components/contexts/AuthContext';
import {useTheme} from '../../../contexts/ThemeContext';
import CrossIcon from "../../../utils/CrossIcon";
import CheckIcon from "../../../utils/CheckIcon";

interface Props {
    user: User;
    onClick: (user: User) => void;
    openModal: (user?: User) => void;
    setSelectedUser: Function
    blockModalToggle: () => void
    restoreModalToggle: () => void
}

const UserItem: FunctionComponent<Props> = ({user, onClick, openModal, setSelectedUser, blockModalToggle, restoreModalToggle}) => {
    const {theme} = useTheme();
    const {isCenterAdmin, isSuperAdmin, isCurrentUser} = useAuth();

    return (
        <div className={`user-item user-list__grid user-item--${theme.modifier}`} onClick={() => onClick(user)}>
            <p className="user user__detail">{`${user.first_name} ${user.last_name}`}</p>
            <p className="user user__detail">{user.email}</p>
            <p className="user user__detail">{user.phone}</p>
            <p className="user user__detail">{user.roles.map((r) => r.name).join(', ')}</p>
            <p className="user user__detail">{user.created_at}</p>
            <p className="user user__detail">{user.activated ? <CheckIcon /> :<CrossIcon />}</p>
            {
                (isCenterAdmin()) || isSuperAdmin() ? (
                    <div className="user user__detail--action">
                        <p
                            className="user user__detail--link"
                            onClick={(e) => {
                                e.stopPropagation();
                                openModal(user);
                            }}
                        >

                            {(!user.sso_identifier && !user.blocked) ? 'Bewerken' : ''}
                        </p>

                        {(!user.sso_identifier && !isCurrentUser(user.id) && !user.blocked) ?
                            <p className="user user__detail--link" onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUser(user);
                                blockModalToggle();
                            }}>Blokkeren</p>
                            : ''}
                        {(!user.sso_identifier && !isCurrentUser(user.id) && user.blocked) ?
                            <p className="user user__detail--link" onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUser(user);
                                restoreModalToggle();
                            }}>Deblokkeren</p>
                            : ''}
                    </div>
                ) : (
                    <p>Geen</p>
                )
            }
        </div>
    );
};

export default UserItem;
