import React, {FunctionComponent, useEffect, useState} from 'react';
import cloud from '../../assets/icons/upload.svg';
import {FormAccessor, input} from 'buro-lib-ts';
import Button from '../layout/buttons/Button';

interface Props {
    imagePath: string | undefined;
    onUpload: (image: File) => Promise<string>;
    onDelete: () => Promise<void>;
}

const ImageUpload: FunctionComponent<Props> = ({imagePath, onUpload, onDelete}) => {

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const [image, setImage] = useState<string|null>();

    useEffect(() => {
        setImage(imagePath);
    }, [imagePath]);

    const uploadImage = () => {
        fileInputRef.current?.click();
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            onUpload(file).then(r => {
                console.log(r);
                setImage(r);
            });
        }
    };

    const deleteImage = () => {
        onDelete().then(() => {
            setImage(null);
        });
    };
    return (
        <div className={'image-upload'}>
            {image !== null &&
                <img src={image} alt={'image'} className={'form-question__image'} width={100}/>
            }
            <input type="file"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={handleImageUpload}
                accept="image/*"/>
            {image !== null &&
                <>
                    <Button onClick={uploadImage}>
                        Wijzig afbeelding
                    </Button>
                    <Button del={true} onClick={deleteImage}>
                        Verwijder afbeelding
                    </Button>
                </>
            }
            {image === null &&
                <>
                    <Button onClick={uploadImage}>
                        Upload afbeelding
                    </Button>

                </>
            }
        </div>
    );
};

export default ImageUpload;
