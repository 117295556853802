import React, {FunctionComponent} from 'react';
import {useNavigate} from '@tanstack/react-router';

import Button from 'components/layout/buttons/Button';
import ConversationList from 'components/client/partials/conversations/ConversationList';
import ResultsBox from 'components/client/partials/conversations/ResultsBox';

import Score from 'networking/models/Score';
import Audit from 'networking/models/Audit';
import AuditCall from 'networking/models/AuditCall';

import AuditRepository from '../../../../networking/repos/AuditRepository';
import {useAuth} from '../../../contexts/AuthContext';

import Modal, {useModal} from '../../partials/modal/Modal';
import AuditDataBox from '../../partials/audits/create/AuditDataBox';
import AuditCriteriaBox, {FormErrorRepeat} from './AuditCriteriaBox';

import {success, warn} from 'helpers/Toast';
import {logError} from '../../../utils/devtool/DevTool';
import {DownloadFile} from '../../../../helpers/Download';
import AuditCommentBox from './AuditCommentBox';
import ConfirmModal from '../../partials/modal/ConfirmModal';
import {AuditsRoute} from '../audits/Audits';
import {ConversationFormRoute} from './form/ConversationForm';

interface Props {
    audit: Audit;
    auditCalls: AuditCall[];
    scores: Score[];
    formErrorRepeat?: FormErrorRepeat;
}

const ConversationsContent: FunctionComponent<Props> = ({audit, auditCalls, scores, formErrorRepeat}) => {
    const {user, isCenterAdmin} = useAuth();
    const navigate = useNavigate();

    const [open, active, toggleModal] = useModal();
    const [deleteModalOpen, deleteModalActive, toggleDeleteModal] = useModal();

    const [auditRepository] = [
        new AuditRepository()
    ];

    const onDeleteClick = () => {
        if (audit && !audit.completed_at) {
            auditRepository.destroy(audit).then(() => {
                success('Audit verwijderd');
                navigate({to: AuditsRoute});
            }).catch((e) => {
                logError(e);

                warn('Oeps, er is iets misgegaan!');
            });
        }
    };

    const onDownloadClick = () => {
        if (audit.report) {
            auditRepository.getFile(audit['id'] ?? 0, 'report').then((report: any) => {
                const blob = new Blob([report], {type: 'application/pdf'});
                DownloadFile(blob,
                    `Auditrapport-${audit.id}-${audit.operator?.first_name} ${audit.operator?.last_name}.pdf`);
            }).catch(logError);
        }
    };

    const onConversationClick = (auditCall: AuditCall) => {
        navigate({to: ConversationFormRoute.fullPath, params: {auditId: audit.id, auditCallId: auditCall.id}});
    };

    const updateComment = (comment: string) => {
        auditRepository.updateComment({...audit, comment}).catch(logError);
    };

    const updateReflection = (reflection: string) => {
        auditRepository.updateReflection({...audit, reflection}).catch(logError);
    };

    const isDoneOr = (bool: boolean) => {
        return audit.completed || bool;
    };

    const isDoneAnd = (bool: boolean) => {
        return audit.completed && bool;
    };

    return (
        <>
            <h2>Audit gesprekken</h2>

            <div className={'conversations-page__content'}>
                <div className={'conversations-page__triagist'}>
                    <div className={'conversations-page__triagist--card'}>
                        <div className={'flex flex--justify-between flex--align-center'}>
                            <span
                                className={'conversations-page__info-text'}>{`${audit?.operator?.first_name} ${audit?.operator?.last_name}`}</span>
                        </div>

                        <div className={'conversations-page__triagist-top-info'}>
                            <div>
                                <p className={'conversations-page__info-title conversations-page__info-title--margin'}>Organisatie</p>
                                <span className={'conversations-page__info-text'}>
                              Meldkamer {audit.operator?.center_name}
                                </span>
                            </div>
                        </div>

                        <div className={'conversations-page__info-box'}>
                            <span className={'conversations-page__info-title'}>Auditor</span>
                            <span
                                className={'conversations-page__info-text'}>{(audit?.auditor?.first_name) ? `${audit?.auditor?.first_name} ${audit?.auditor?.last_name}` : 'Anoniem'}</span>
                        </div>

                        <div className={'conversations-page__info-box'}>
                            <span className={'conversations-page__info-title'}>Aangemaakt op</span>
                            <span className={'conversations-page__info-text'}>{audit?.created_at}</span>
                        </div>
                    </div>

                    {(isCenterAdmin() && !audit.completed_at) &&
                        <>
                            <div className={'conversations-page__actions'}>
                                <Button onClick={toggleModal}>Aanpassen</Button>

                                <Button onClick={toggleDeleteModal} del>
                                    Verwijderen
                                </Button>
                            </div>
                            <Modal isOpen={open} active={active} onCloseClick={toggleModal}
                                className={'audit-create-box__modal'}
                                customBody={true}>
                                <AuditDataBox
                                    onCancelClick={toggleModal}
                                    onAuditCreate={() => null}
                                    audit={audit}
                                    auditCalls={auditCalls}/>
                            </Modal>
                            <ConfirmModal isOpen={deleteModalOpen} isActive={deleteModalActive}
                                onCloseClick={toggleDeleteModal}
                                onDeleteConfirm={onDeleteClick} confirmObjectName={'audit'}/>
                        </>
                    }

                    <AuditCommentBox title={'Opmerking auditor'} comment={audit.comment || ''}
                        placeholder={'Geen opmerking'} disabled={isDoneOr(user?.id !== audit.auditor_id)}
                        onSave={updateComment}/>
                    <AuditCommentBox title={'Reflectie triagist'} comment={audit.reflection || ''}
                        placeholder={'Nog geen reflectie ingevuld'}
                        disabled={user?.id !== audit.operator?.id} onSave={updateReflection}/>

                    {isDoneAnd(scores.length > 0) &&
                        <>
                            <ResultsBox scores={scores}/>
                            <AuditCriteriaBox audit={audit} repeatCounts={formErrorRepeat}/>
                        </>
                    }

                    {isDoneAnd(audit?.report) &&
                        <a onClick={onDownloadClick}>
                            <Button>Download rapport</Button>
                        </a>
                    }
                </div>

                <div className={'conversations-page__conversation-list-container'}>
                    <div className={'conversations-page__conversations'}>
                        <ConversationList conversations={auditCalls} onConversationClick={onConversationClick}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConversationsContent;
