import {AuthContextInterface, useAuth} from 'components/contexts/AuthContext';
import {QueryClient} from '@tanstack/react-query';
import {
    createBrowserHistory,
    createRootRouteWithContext,
    createRouter,
    Outlet,
    RouterProvider
} from '@tanstack/react-router';
import React, {FunctionComponent} from 'react';
import {AnimatePresence} from 'framer-motion';
import {GuestRoute} from './Auth';
import {UserRoute} from './User';
import {AdminRoute} from './Admin';
import {LandingRoute} from '../components/client/pages/auth/Landing';
import {LoginRoute} from '../components/client/pages/auth/Login';
import {DashboardRoute} from '../components/client/pages/dashboard/Dashboard';
import {CentersPageRoute} from '../components/admin/pages/centers/CentersPage';
import {AuditsIndexRoute, AuditsRoute} from '../components/client/pages/audits/Audits';
import {CentersRoute} from '../components/client/pages/centers/Centers';
import {StatisticsRoute} from '../components/client/pages/statistics/Statistics';
import {UsersRoute} from '../components/client/pages/users/Users';
import {UserAuditsRoute} from '../components/client/pages/users/UserAudits/UserAudits';
import {AccountRoute} from '../components/client/pages/account/Account';
import {CenterDetailPageRoute} from '../components/admin/pages/centers/CenterDetailPage';
import {FormRoute} from '../components/client/pages/form/Form';
import {SettingsRoute} from '../components/client/pages/settings/Settings';
import {AuditsAboutMeRoute} from '../components/client/pages/audits/AuditsAboutMe';
import {AuditsForMeRoute} from '../components/client/pages/audits/AuditsForMe';
import {CenterAdminsRoute} from '../components/admin/pages/centers/CenterAdmins';
import {ReportFeedbackRoute} from '../components/client/pages/user_feedback/ReportFeedback';
import {ConversationsRoute} from '../components/client/pages/conversations/Conversations';
import {AdminFormsRoute} from '../components/admin/pages/forms/FormsPage';
import {ConversationFormRoute} from '../components/client/pages/conversations/form/ConversationForm';
import {AdminFormDetailRoute} from '../components/admin/pages/forms/FormDetail/FormDetail';
import {SuperAdminsRoute} from '../components/admin/pages/superadmins/SuperAdmins';
import {EventsPageRoute} from '../components/admin/pages/events/EventsPage';
import {LoginsPageRoute} from '../components/admin/pages/logins/LoginsPage';
import {UserFeedbackRoute} from '../components/admin/pages/user_feedback/UserFeedback';
import {LoginSSORoute} from '../components/client/pages/auth/LoginSSO';
import {PasswordResetRoute} from '../components/client/pages/auth/password/PasswordReset';
import {NewPasswordRoute} from '../components/client/pages/auth/password/NewPassword';
import {FinishRegisterRoute, RegisterRoute} from '../components/client/pages/auth/register/Register';

export const rootRoute = createRootRouteWithContext<{
    queryClient: QueryClient,
    auth: AuthContextInterface,
}>()({
    component: () => <div className="layout">
        <React.StrictMode>
            <AnimatePresence mode={'wait'}>
                <Outlet/>
            </AnimatePresence>
        </React.StrictMode>
    </div>
});
// @ts-ignore
const routeTree = rootRoute.addChildren([
    // @ts-ignore
    GuestRoute.addChildren([LandingRoute, LoginRoute, LoginSSORoute, PasswordResetRoute, NewPasswordRoute, RegisterRoute, FinishRegisterRoute]),
    // @ts-ignore
    UserRoute.addChildren([DashboardRoute, AuditsRoute.addChildren([AuditsIndexRoute, ConversationsRoute, ConversationFormRoute]), CentersRoute, StatisticsRoute, UsersRoute, UserAuditsRoute, AccountRoute, FormRoute, SettingsRoute, AuditsAboutMeRoute, AuditsForMeRoute, ReportFeedbackRoute]),
    // @ts-ignore
    AdminRoute.addChildren([CentersPageRoute, CenterDetailPageRoute, CenterAdminsRoute, AdminFormsRoute, AdminFormDetailRoute, SuperAdminsRoute, EventsPageRoute, LoginsPageRoute, UserFeedbackRoute]),
]);

const browserHistory = createBrowserHistory();

const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    defaultStaleTime: 5000,
    history: browserHistory,
    defaultPreloadStaleTime: 0,
    context: {
        queryClient: undefined!,
        auth: undefined!
    },
});

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

export const Router: FunctionComponent<{ client: QueryClient }> = ({client}) => {
    const auth = useAuth();
    return (
        <RouterProvider router={router} context={{auth, client}}/>
    );
};
