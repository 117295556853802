import React, {FunctionComponent, useState} from 'react';
import MeetingList from './MeetingList';
import {Meeting} from '../../../../../networking/models/Meeting';
import Modal, {useModal} from '../../modal/Modal';
import {MutateMeetingModal} from './modal/MutateMeetingModal';
import {FormAccessor} from 'buro-lib-ts';
import {MeetingRequest} from '../../../../../networking/requests/MeetingRequest';
import {useMeetingMutation} from '../../../../query/Meeting/Mutation';
import {success, warn} from '../../../../../helpers/Toast';
import {logError} from '../../../../utils/devtool/DevTool';
import {useAuth} from '../../../../contexts/AuthContext';
import {MeetingModal} from './modal/MeetingModal';
import ConfirmModal from '../../modal/ConfirmModal';

interface Props {
    auditId: number
    meetings: Meeting[]
}

const MeetingsContent: FunctionComponent<Props> = ({auditId, meetings}) => {

    const [mutateModalOpen, mutateModalActive, toggleMutateModal] = useModal();

    const [displayModalOpen, displayModalActive, toggleDisplayModal] = useModal();

    const [deleteModalOpen, deleteModalActive, toggleDeleteModal] = useModal();

    const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();
    const {createMeeting, updateMeeting, deleteMeeting} = useMeetingMutation(auditId);

    const auth = useAuth();

    const closeMutateModal = () => {
        clearSelectedMeeting();
        toggleMutateModal();
    };

    const closeDisplayModal = () => {
        clearSelectedMeeting();
        toggleDisplayModal();
    };

    const clearSelectedMeeting = () => {
        setSelectedMeeting({name: '', date: '', time: '', comment: ''});
    };

    const onMeetingDelete = async () => {
        if (selectedMeeting != null) {
            deleteMeeting.mutateAsync(selectedMeeting.id!)
                .then(() => {
                    success('De vergadering is verwijderd');
                    clearSelectedMeeting();
                })
                .catch((e: any) => {
                    logError(e);
                    warn('Oeps, er is iets misgegaan!');
                });
            toggleDeleteModal();
        }
    };

    const onMeetingUpdate = (id: number, form: FormAccessor<MeetingRequest>) => {
        if (!form.isValid) return warn('Oeps, er is iets misgegaan!');

        updateMeeting.mutateAsync({id: id, request: form.values})
            .then(() => {
                success('De vergadering is bewerkt.');
                closeMutateModal();
                clearSelectedMeeting();
            })
            .catch((e: any) => {
                logError(e);
                warn('Oeps, er is iets misgegaan!');
            });
    };

    const onMeetingCreate = (form: FormAccessor<MeetingRequest>) => {
        if (!form.isValid) return warn('Oeps, er is iets misgegaan!');

        createMeeting.mutateAsync(form.values)
            .then(() => {
                success('De vergadering is aangemaakt.');
                closeMutateModal();
                clearSelectedMeeting();
            })
            .catch((e: any) => {
                logError(e);
                warn('Oeps, er is iets misgegaan!');
            });
    };

    const onCreateClick = (form: FormAccessor<MeetingRequest>) => {
        onMeetingCreate(form);
    };

    const onDeleteClick = (meeting: Meeting) => {
        setSelectedMeeting(meeting);
        toggleDeleteModal();
    };

    const onUpdateClick = (meeting: Meeting) => {
        setSelectedMeeting(meeting);
        toggleMutateModal();
    };

    const onMeetingClick = (meeting: Meeting) => {
        setSelectedMeeting(meeting);
        toggleDisplayModal();
    };

    return (
        <>
            <div className={'top-container'}>
                <h2>Vergaderingen</h2>
                {(auth.isCoach() || auth.isCenterAdmin()) &&
                    <button className={'button button-basic button-basic button-basic--light'}
                        onClick={toggleMutateModal}>Nieuwe vergadering</button>}
            </div>
            <Modal isOpen={mutateModalOpen} active={mutateModalActive} onCloseClick={closeMutateModal}
                className="user-modal">
                <MutateMeetingModal onUpdate={onMeetingUpdate} onCreate={onCreateClick} meeting={selectedMeeting}
                    onClose={closeMutateModal}/>
            </Modal>

            <Modal isOpen={displayModalOpen} active={displayModalOpen} onCloseClick={closeDisplayModal}
                className="user-modal">
                <MeetingModal meeting={selectedMeeting}
                    onClose={closeDisplayModal}/>
            </Modal>

            <ConfirmModal isOpen={deleteModalOpen} isActive={deleteModalActive} onCloseClick={toggleDeleteModal}
                onDeleteConfirm={onMeetingDelete} confirmObjectName={'meeting'}/>

            <MeetingList onDeleteClick={onDeleteClick} onUpdateClick={onUpdateClick} onMeetingClick={onMeetingClick}
                meetings={meetings} setSelectedMeeting={setSelectedMeeting}
                toggleDeleteModal={toggleDeleteModal}/>
        </>
    );
};

export default MeetingsContent;
