import React, {FunctionComponent, useMemo, useState} from 'react';
import {select, useForm} from 'buro-lib-ts';
import {Doughnut} from 'react-chartjs-2';

import User from '../../../../../networking/models/User';
import BoxHeader from '../../../partials/box/BoxHeader';
import BoxContent from '../../../partials/box/BoxContent';
import Select from '../../../partials/input/select/Select';
import ScoresPerQuestion from '../../../../../networking/models/ScoresPerQuestion';
import Button from '../../../../layout/buttons/Button';
import Box from '../../../partials/box/Box';
import CenterRepository from '../../../../../networking/repos/CenterRepository';

interface Form {
    criteria: number;
    operator: number;
}

interface OperatorScores {
    id: number;
    scores: ScoresPerQuestion[];
}

interface DoughnutData {
    yes: number,
    no: number,
    nvt: number
}

interface Props {
    operators: User[];
    questions: ScoresPerQuestion[];
    center: number;
    form: any;
}

// TODO: TEST form change
const OperatorScoresBox: FunctionComponent<Props> = ({operators, questions, center, form}) => {

    const [formState, onFormStateChange] = useForm<Form>({
        operator: select<Form, number>(-1, null, []),
        criteria: select<Form, number>(-1, null, []),
    });

    const [scoresPerOperator, setScoresPerOperator] = useState<OperatorScores[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [graphDoughnutData, setGraphDoughnutData] = useState<DoughnutData>({
        yes: 0,
        no: 0,
        nvt: 0
    });

    const dataObject: any = useMemo(() => {

        const colorYes = '#2bda7e';
        const colorNo = '#d71e00';
        const colorNvt = '#f9dd4b';

        return {
            labels: ['Ja', 'Nee', 'N.v.t.'],
            datasets: [
                {
                    label: 'Scores',
                    backgroundColor: [
                        colorYes,
                        colorNo,
                        colorNvt
                    ],
                    hoverBackgroundColor: [
                        colorYes,
                        colorNo,
                        colorNvt
                    ],
                    data: [
                        Math.round(graphDoughnutData.yes * 100) / 100,
                        Math.round(graphDoughnutData.no * 100) / 100,
                        Math.round(graphDoughnutData.nvt * 100) / 100
                    ]
                }
            ]
        };
    }, [graphDoughnutData]);

    const graphDoughnutOptions = {
        animation: {
            duration: 0
        },
        plugins: {
            title: {
                display: scoresPerOperator.length > 0,
                text: (scoresPerOperator.length > 0) ? (`Scores voor criteria van formulier '${form?.name}'`) : (''),
            }
        },
    };

    const hasNoData = () => {
        return !graphDoughnutData.no && !graphDoughnutData.yes && !graphDoughnutData.nvt;
    };

    const getScores = async () => {
        if (formState.values.operator === undefined || formState.values.criteria === undefined) return;

        setShowLoader(true);

        const centerRepo = new CenterRepository();
        let scores = scoresPerOperator.find(score => score.id === formState.values.operator)?.scores;

        if (!scores) {
            scores = await centerRepo.getScoresPerFormPerOperator(center, form.id, formState.values.operator);
            setScoresPerOperator([...scoresPerOperator, {id: formState.values.operator, scores}]);
        }

        const score = scores.find(score => score.id === formState.values.criteria);

        setGraphDoughnutData({
            yes: parseFloat(score?.yes ?? '0'),
            no: parseFloat(score?.no ?? '0'),
            nvt: parseFloat(score?.nvt ?? '0')
        });

        setShowLoader(false);
    };

    return (
        <Box>
            <BoxHeader>
                Gemiddelde scores per criterium per medewerker per formulier
            </BoxHeader>
            <BoxContent>
                <div className="statistics-page__line statistics-page__row">
                    <div className="statistics-page__col--left">
                        <div className="statistics-page__filters">
                            <Select
                                title={'Kies criterium'}
                                options={questions}
                                onSelect={(e) => onFormStateChange({id: 'criteria', value: e.id})}
                                renderOption={(score: ScoresPerQuestion) => (score.question)} />

                            <br />

                            <Select
                                title={'Kies medewerker'}
                                options={operators}
                                onSelect={(e) => onFormStateChange({id: 'operator', value: e.id})}
                                renderOption={(user: User) => (user.full_name)} />

                            <br/>

                            <Button onClick={getScores}>Genereer diagram</Button>
                        </div>
                    </div>

                    <div className="statistics-page__col--right">
                        <div className="statistics-page__doughnut--wrapper">
                            <div className="statistics-page__doughnut--canvas">
                                {
                                    !scoresPerOperator.length ?
                                        <div className="statistics-page__placeholder">
                                            <p>Selecteer een criterium & werknemer</p>
                                        </div> :
                                        hasNoData() ?
                                            <div
                                                className={`statistics-page__loader-container ${showLoader ? 'statistics-page__loader-container--bright' : ''}`}>
                                                <p>Geen gegevens voor deze werknemer.</p>
                                            </div> :
                                            <Doughnut data={dataObject} options={graphDoughnutOptions}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </BoxContent>
        </Box>
    );
};

export default OperatorScoresBox;
