import React, { FunctionComponent } from 'react';

interface Props {
    children: any;
}

const TabBarNavigation: FunctionComponent<Props> = ({ children }) => {
    return (
        <div className={'tab-navigation__bar--bottom'}>
            { children }
        </div>
    );
};

export default TabBarNavigation;