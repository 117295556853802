import React, {FunctionComponent} from 'react';
import SidebarMenu from '../../layout/sidebar/SidebarMenu';
import SidebarMenuItem from '../../layout/sidebar/SidebarMenuItem';

import {ReactComponent as ContactsIcon} from '../../../assets/icons/contacts-border.svg';
import {ReactComponent as ContactsIconActive} from '../../../assets/icons/contacts.svg';
import {ReactComponent as ListIcon} from '../../../assets/icons/list-border.svg';
import {ReactComponent as ListIconActive} from '../../../assets/icons/list.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/icon-users-border.svg';
import {ReactComponent as UserIconActive} from '../../../assets/icons/icon-users.svg';
import {ReactComponent as SpyIcon} from '../../../assets/icons/spy-border.svg';
import {ReactComponent as SpyIconActive} from '../../../assets/icons/spy.svg';
import {ReactComponent as KeyIcon} from '../../../assets/icons/key-password-border.svg';
import {ReactComponent as KeyIconActive} from '../../../assets/icons/key-password.svg';
import {ReactComponent as BugIcon} from '../../../assets/icons/bug.svg';
import {CentersPageRoute} from '../pages/centers/CentersPage';
import {AdminFormsRoute} from '../pages/forms/FormsPage';
import {SuperAdminsRoute} from '../pages/superadmins/SuperAdmins';
import {EventsPageRoute} from '../pages/events/EventsPage';
import {LoginsPageRoute} from '../pages/logins/LoginsPage';
import {UserFeedbackRoute} from '../pages/user_feedback/UserFeedback';

const AdminSidebarMenu: FunctionComponent = () => {
    return (
        <SidebarMenu>
            <SidebarMenuItem icon={ContactsIcon} activeIcon={ContactsIconActive}
                to={CentersPageRoute.fullPath}>Meldkamers</SidebarMenuItem>
            <SidebarMenuItem icon={ListIcon} activeIcon={ListIconActive}
                to={AdminFormsRoute.fullPath}>Formulieren</SidebarMenuItem>
            <SidebarMenuItem icon={UserIcon} activeIcon={UserIconActive}
                to={SuperAdminsRoute.fullPath}>Superbeheerders</SidebarMenuItem>
            <SidebarMenuItem icon={SpyIcon} activeIcon={SpyIconActive}
                to={EventsPageRoute.fullPath}>Logs</SidebarMenuItem>
            <SidebarMenuItem icon={KeyIcon} activeIcon={KeyIconActive}
                to={LoginsPageRoute.fullPath}>Inlogpogingen</SidebarMenuItem>
            <SidebarMenuItem icon={BugIcon} activeIcon={BugIcon}
                to={UserFeedbackRoute.fullPath}>Bug meldingen</SidebarMenuItem>
        </SidebarMenu>
    );
};

export default AdminSidebarMenu;
