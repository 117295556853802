import React, {FunctionComponent} from 'react';
import {BuroApp} from 'buro-lib-ts';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AuthContextProvider from './components/contexts/AuthContext';
import SideBarContextProvider from './components/contexts/SideBarContext';
import ThemeContextProvider from './components/contexts/ThemeContext';
import 'react-toastify/dist/ReactToastify.css';
import AuditToolApp from './config/App';
import {ToastContainer} from 'react-toastify';
import {isDev} from './helpers/Env';
import DevTool from './components/utils/devtool/DevTool';
import {Router} from './routing/Router';

const queryClient = new QueryClient();

interface ProvidersProps {
    children: any;
}

const Providers: FunctionComponent<ProvidersProps> = ({children}) => {
    return (
        <ThemeContextProvider>
            <SideBarContextProvider>
                <AuthContextProvider>
                    {children}
                </AuthContextProvider>
            </SideBarContextProvider>
        </ThemeContextProvider>
    );
};

const app = new AuditToolApp();

const App: FunctionComponent = () => {
    return (
        <BuroApp app={app}>
            <QueryClientProvider client={queryClient}>
                <Providers>
                    {
                        isDev() && <DevTool/>
                    }
                    <Router client={queryClient}/>
                    <ToastContainer position="bottom-right" hideProgressBar/>
                </Providers>
            </QueryClientProvider>
        </BuroApp>
    );
};

export default App;
