import React, {FunctionComponent} from 'react';
import {useTheme} from '../../../contexts/ThemeContext';
import Button from '../../../layout/buttons/Button';
import CancelButton from '../../../layout/buttons/CancelButton';

interface Props {
    isOpen: boolean
    isActive: boolean
    onCloseClick: () => void
    onDeleteConfirm: Function
    confirmObjectName?: string
    confirmObjectAction?: string
}

const ConfirmModal: FunctionComponent<Props> = ({
    isOpen, isActive, onCloseClick,
    onDeleteConfirm, confirmObjectName, confirmObjectAction
}) => {
    const {theme} = useTheme();
    const getClassName = () => {
        return `
            confirm-modal confirm-modal--${theme.modifier} 
            confirm-modal--${isOpen ? 'open' : 'closed'} 
            confirm-modal--${isActive ? 'active' : 'inactive'} 
             
            ${!isActive ? 'confirm-modal--hidden' : ''}
        `;
    };

    const getTitle = () => {
        return `Weet u zeker dat u deze ${confirmObjectName} wilt ${confirmObjectAction ? confirmObjectAction : 'verwijderen'}?`;
    };

    return (
        <div className={getClassName()} tabIndex={0}>
            <div className={'confirm-modal__overlay'}/>

            <div className={'confirm-modal__card-container'}>
                <div className={'confirm-modal__card'}>
                    <div className={'confirm-modal__header'}>
                        <h1>{getTitle()}</h1>
                    </div>

                    <div className={'confirm-modal__content'}>
                        <div className={'confirm-modal__controls'}>
                            <CancelButton onClick={onCloseClick}>Annuleren</CancelButton>
                            <Button onClick={onDeleteConfirm} del>
                                {confirmObjectAction ?
                                    confirmObjectAction.charAt(0).toUpperCase() + confirmObjectAction.slice(1)
                                    : 'Verwijderen'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
