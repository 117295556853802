import React, {FunctionComponent} from 'react';

import {useTheme} from '../../../../contexts/ThemeContext';
import {Meeting} from '../../../../../networking/models/Meeting';
import MeetingItem from './MeetingItem';
import {useAuth} from '../../../../contexts/AuthContext';

interface Props {
    meetings?: Meeting[]
    onDeleteClick: (meeting: Meeting) => void
    onUpdateClick: (meeting: Meeting) => void,
    onMeetingClick: (meeting: Meeting) => void,
    setSelectedMeeting: Function
    toggleDeleteModal: () => void
}

const MeetingList: FunctionComponent<Props> = ({meetings, onUpdateClick, onDeleteClick, onMeetingClick}) => {

    const {theme} = useTheme();
    const auth = useAuth();

    return (
        <div className={`item-list item-list--${theme.modifier}`}>
            <div className="item-list__grid item-list__header">
                <span className={'item-list__title'}>#</span>
                <span className={'item-list__title'}>Naam</span>
                <span className={'item-list__title'}>Datum</span>
                <span className={'item-list__title'}>Tijd</span>
                <span className={'item-list__title'}>Opmerking</span>
                {(auth.isCoach() || auth.isCenterAdmin()) && <span className={'item-list__title'}>Actie</span>}
            </div>
            {meetings &&
                <div className="item-list__items">
                    {meetings.map((meeting) =>
                        <MeetingItem key={meeting.id} onDeleteClick={onDeleteClick} onUpdateClick={onUpdateClick}
                            meeting={meeting} onMeetingClick={onMeetingClick}/>
                    )}
                </div>
            }
        </div>
    );
};

export default MeetingList;
