import React, {FunctionComponent} from 'react';
import {Meeting} from '../../../../../networking/models/Meeting';
import {useTheme} from '../../../../contexts/ThemeContext';
import {useAuth} from '../../../../contexts/AuthContext';
import {convertDateToDutchFormat} from '../../../../../helpers/Utils';

interface Props {
    meeting: Meeting
    onDeleteClick: (meeting: Meeting) => void
    onUpdateClick: (meeting: Meeting) => void
    onMeetingClick: (meeting: Meeting) => void
}

const MeetingItem: FunctionComponent<Props> = ({meeting, onUpdateClick, onDeleteClick, onMeetingClick}) => {

    const auth = useAuth();

    const {theme} = useTheme();

    const parseComment = (comment: string) => {
        if (!comment) return 'Geen opmerking';
        if (comment.length > 200) {
            return comment.substring(0, 200) + '...';
        }
        return comment;
    };

    const clickMeeting = () => {
        onMeetingClick(meeting);
    };

    return (
        <>
            <div className={`item-list__grid item item--${theme.modifier}`}>
                <span className={'item__text'} onClick={clickMeeting}>{meeting.id}</span>
                <span className={'item__text'} onClick={clickMeeting}>{meeting.name}</span>
                <span className={'item__text'} onClick={clickMeeting}>{convertDateToDutchFormat(meeting.date)}</span>
                <span className={'item__text'} onClick={clickMeeting}>{meeting.time}</span>
                <span className={'item__text item__comment'}
                    onClick={clickMeeting}>{parseComment(meeting.comment)}</span>
                {(auth.isCoach() || auth.isCenterAdmin()) &&
                    <div className={'item__text'}>
                        <p className={'item__link'} onClick={() => onUpdateClick(meeting)}>Bewerken</p>
                        <p className={'item__link'} onClick={() => onDeleteClick(meeting)}>Verwijderen</p>
                    </div>
                }
            </div>
        </>
    );
};
export default MeetingItem;
