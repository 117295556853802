import React, {FunctionComponent} from 'react';
import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {useFormDetailQueryConfig} from '../../../../query/Form/Form';
import {useFormPartIndexQueryConfig} from '../../../../query/FormPart/Query';
import Page from '../../../../layout/Page';
import FormDetailContent from './FormDetailContent';
import Form from '../../../../../networking/models/Form';
import FormPart from '../../../../../networking/models/FormPart';
import {createRoute,useParams} from '@tanstack/react-router';
import {AdminRoute} from '../../../../../routing/Admin';

export const AdminFormDetailRoute = createRoute({
    getParentRoute: () => AdminRoute,
    path: '/forms/$formId',
    component: () => <FormDetail />
});

const FormDetail: FunctionComponent = () => {
    const { formId } = useParams({strict: false}) as {formId: number};


    const [formConfig] = useFormDetailQueryConfig(formId);
    const [formPartConfig] = useFormPartIndexQueryConfig(formId);

    const query: [UseQueryResult<Form>, UseQueryResult<FormPart[]>] = [
        useQuery(formConfig),
        useQuery(formPartConfig),
    ];

    return (
        <Page className={'admin-form-detail'}>
            { (query[0].data && query[1].data) &&
                <FormDetailContent
                    form={query[0].data}
                    parts={query[1].data} />
            }
        </Page>
    );
};

export default FormDetail;
