import React, { FunctionComponent } from 'react';
import TabBarNavigation from './TabBarNavigation';
import Tab from './Tab';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    children: any;
}

const TabBar: FunctionComponent<Props> = ({ children }) => {
    const { theme } = useTheme();

    const childrenAreTabs = (): boolean => {
        if(!Array.isArray(children)) return false;

        for(const child of children)  {
            if(child.type === Tab) {
                return true;
            }
        }


        return false;
    };

    return (
        <div className={`tab-navigation__bar tab-navigation__bar--${theme.modifier}`}>
            { childrenAreTabs()
                ? (<TabBarNavigation>{ children }</TabBarNavigation>)
                : children }
        </div>
    );
};

export default TabBar;
