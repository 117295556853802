import React, {FunctionComponent} from 'react';

// Icons & logo's
import LogoRegular from 'assets/logos/logo-regular.png';
import LogoWhite from 'assets/logos/logo-white.png';
import {useTheme} from '../../../../contexts/ThemeContext';

interface Props {
    region?: string
}

const AuthHeader: FunctionComponent<Props> = (props) => {
    const { theme } = useTheme();

    return (
        <div className="auth__header">
            <img src={theme.isDark ? LogoWhite : LogoRegular} alt="" className="auth__header-logo" />
            <div className="auth__header-text">
                <p className="auth__header-text--big">Meldkamercoach</p>
                {props.region && <p className="auth__header-text--small">{props.region}</p>}
            </div>
        </div>
    );
};

export default AuthHeader;
