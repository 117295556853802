import React, {FunctionComponent, useEffect} from 'react';
import {Meeting} from '../../../../../../networking/models/Meeting';
import {useMeetingForm} from '../../../../../form/Meeting';
import Input from '../../../input/Input';
import CancelButton from '../../../../../layout/buttons/CancelButton';
import Button from '../../../../../layout/buttons/Button';
import {parseError} from '../../../../../../helpers/Validation';
import TextArea from '../../../input/TextArea';

interface Props {
    meeting?: Meeting
    onClose: () => void
}

export const MeetingModal: FunctionComponent<Props> = ({meeting, onClose}) => {
    if(!meeting) return null;



    return (
        <>
            <div>
                <h2 className="meeting-modal meeting-modal__title">
                    Vergadering {meeting.name}
                </h2>

                {/*display each value */}

                <div className="meeting-modal meeting-modal__item">
                    <span className="meeting-modal meeting-modal__item__title">Datum</span>
                    <span className="meeting-modal meeting-modal__item__content">{meeting.date}</span>
                </div>

                <div className="meeting-modal meeting-modal__item">
                    <span className="meeting-modal meeting-modal__item__title">Tijd</span>
                    <span className="meeting-modal meeting-modal__item__content">{meeting.time}</span>
                </div>


                <div className="meeting-modal meeting-modal__item">
                    <span className="meeting-modal meeting-modal__item__title">Opmerking</span>
                    <span className="meeting-modal meeting-modal__item__content">{meeting.comment}</span>
                </div>





                <div className="user-modal user-modal__button-group">
                    <CancelButton onClick={onClose}>Sluiten</CancelButton>
                </div>
            </div>
        </>
    );
};
