import React, {useState, useEffect, useCallback} from 'react';
import Page from 'components/layout/Page';
import Progress from '../../../partials/auth/Progress';
import AddPassword, {PasswordsObject} from './AddPassword';
import AddGoogle2FA from './AddGoogle2FA';
import {success} from 'helpers/Toast';
import AuthRepository from '../../../../../networking/repos/AuthRepository';
import {logError} from '../../../../utils/devtool/DevTool';
import {createRoute, useParams,useNavigate} from '@tanstack/react-router';
import {LandingRoute} from '../Landing';
import {LoginRoute} from '../Login';
import {GuestRoute} from '../../../../../routing/Auth';

interface Stages {
    password: boolean;
    google2fa: boolean;
}

export const RegisterRoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/register',
    component: () => <Register />,
});

export const FinishRegisterRoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/finish-register/$token',
    component: () => <Register />,
});

const Register = () => {
    const {token} = useParams({strict: false}) as {token?: string};

    const [stages, setStages] = useState<Stages>({} as Stages);
    const [currentStage, setCurrentStage] = useState<number>(0);

    const navigate = useNavigate();

    const [authRepository] = [
        new AuthRepository()
    ];

    const getStages = useCallback(async () => {
        authRepository.getUser(token!)
            .then(res => setStages(res.stages))
            .catch((e) => {
                logError(e);
                navigate({to: LandingRoute.fullPath});
            });
    }, [token]);

    useEffect(() => {
        getStages().catch(logError);
    }, [getStages]);

    useEffect(() => {
        if (!stages.password) {
            setCurrentStage(0);
            return;
        }

        if (!stages.google2fa) {
            setCurrentStage(1);
            return;
        }

        if (stages.password && stages.google2fa) {
            //All stages have been completed, redirect to login page.
            success('U kunt nu inloggen.');
            navigate({to: LoginRoute.fullPath});
        }
    }, [stages, setCurrentStage]);

    const addPassword = async (passwords: PasswordsObject) => {
        try {
            await authRepository.addPassword({...passwords, token: token!});
            getStages().then();
        } catch (requestError) {
            logError(requestError);
        }
    };

    const addGoogle2FA = () => {
        getStages().then();
    };

    return (
        <Page className="auth__page">
            <div className="auth__page__form">
                <div className="auth__page__form__row auth__page__form__row__meldkamer">
                    <div className="auth__page__form__row__col"/>
                </div>

                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">{(stages.password) ? 'Koppelen aan authenticator' : 'Kies een wachtwoord'}</h1>
                </div>

                <div className="auth__page__form__row">
                    <Progress currentStage={currentStage} totalStages={Object.keys(stages).length}/>
                </div>

                {
                    (currentStage === 0) ? (
                        <AddPassword handlePasswordsSubmit={addPassword}/>
                    ) : null
                }

                {
                    (currentStage === 1) ? (
                        <AddGoogle2FA token={token!} handle2FASubmit={addGoogle2FA}/>
                    ) : null
                }

                <br/>
            </div>
        </Page>
    );
};

export default Register;
