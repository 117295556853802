import {ModelRepository, RequestMethod} from 'buro-lib-ts';
import {Roles} from 'helpers/Auth';

import User from '../models/User';
import UserTransformer from './transformers/UserTransformer';
import {CenterUserRequest} from '../requests/CenterUser';

class UserRepository extends ModelRepository<User> {
    private transformer = new UserTransformer();

    public constructor(center: number) {
        super(`/centers/${center}/users`);
    }

    save(model: User): Promise<User> {
        return super.save(this.transformer.transform(model));
    }

    create(request: CenterUserRequest) {
        return this.request<User>(this.url(), RequestMethod.POST, request).send();
    }

    update(id: number, request: CenterUserRequest) {
        return this.request<User>(this.eUrl(id), RequestMethod.PUT, request).send();
    }

    softDelete(id: number) {
        return this.request<User>(this.eUrl(id) + '/softDelete', RequestMethod.POST).send();
    }
    restore(id: number) {
        return this.request<User>(this.eUrl(id, `/restore`), RequestMethod.POST).send();
    }

    public getAuditors() {
        return this.getUsersForRole(Roles.AUDITOR);
    }

    public getTriagists() {
        return this.getUsersForRole(Roles.OPERATOR);
    }

    public getAdmins() {
        return this.getUsersForRole(Roles.CENTER_ADMIN);
    }

    private getUsersForRole(role: string) {
        return this.request<User[]>(this.url(`?role=${role}`), RequestMethod.GET).send();
    }

    public allWithBlocked(role?: string) {
        return this.request<User[]>(this.url(`?withBlocked=1${role ? '&role=' + role : ''}`), RequestMethod.GET).send();
    }   

    get(userId: number) {
        return this.request<User>(this.eUrl(userId), RequestMethod.GET).send();
    }
}

export default UserRepository;
