import React, {ChangeEvent, FunctionComponent, useState} from 'react';

interface Props {
    onChange: (date: string) => void;
    default: string;
    type: string;
    id?: string;
}

const DateInput: FunctionComponent<Props> = (props) => {
    const [value, setValue] = useState<string>(props.default);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);

        props.onChange(e.target.value);
    };

    return (
        <div className="date-input-container">
            <input className={'date-input'} min={2000} max={2100} value={value} type={props.type} onChange={handleChange} id={props.id} />
        </div>
    );
};

export default DateInput;
