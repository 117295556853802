import React, {FunctionComponent, KeyboardEvent, useEffect, useRef, useState} from 'react';
import {FormAccessor, OnFormChangeCallback, TabContentProps} from 'buro-lib-ts';

import TabContentLeft from '../TabContentLeft';
import TabContentRight from '../TabContentRight';

import Box from 'components/client/partials/box/Box';
import BoxHeader from '../../../../partials/box/BoxHeader';
import BoxContent from '../../../../partials/box/BoxContent';
import Score from '../../../../../../networking/models/Score';

import ResultsBox from '../../../../partials/conversations/ResultsBox';
import FormQuestionBox from '../../../../partials/conversations/form/FormQuestionBox';

import {BoxData} from '../../../../partials/box/Box';
import FormPart from '../../../../../../networking/models/FormPart';
import Question from '../../../../../../networking/models/Question';
import Audit from '../../../../../../networking/models/Audit';
import Button from '../../../../../layout/buttons/Button';
import BoxList from '../../../../partials/box/BoxList';
import FormPartShortcut from '../FormPartShortcut';
import CircleLoader from '../../../../partials/loading/CircleLoader';
import TextArea from '../../../../partials/input/TextArea';

export interface CommentForm {
    comment: string;
}

export interface Props extends TabContentProps {
    audit?: Audit;
    scores: Score[]
    parts: FormPart[];
    setParts: (parts: FormPart[]) => void;
    onQuestionChange: (question: Question, answer: number) => void;
    commentForm: FormAccessor<CommentForm>;
    onUpdateComment: () => void;
    onCommentChange: OnFormChangeCallback<CommentForm>;
    formFinished: boolean;
    disabled: boolean;
    ntsUrl?: string;
    canGoNext?: boolean;
    next?: () => void;
    userIsNotAuditor: boolean;
}

const ProtocolTab: FunctionComponent<Props> = ({
    next, onCommentChange, parts,
    setParts, canGoNext,
    onQuestionChange, disabled, commentForm, onUpdateComment,
    ntsUrl, audit, scores, userIsNotAuditor
}) => {

    const [showType, setShowType] = useState<boolean>(!!audit?.completed_at);

    const [currentPart, setCurrentPart] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<number>(disabled ? -1 : 0);

    const [questionBoxes, setQuestionBoxes] = useState<BoxData[]>([]);
    const [formEditable, setFormEditable] = useState<boolean>(true);
    const [lastCursorPos, setLastCursorPos] = useState<number>(0);

    const content = useRef<HTMLDivElement>(null);
    const tabRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const sKey = 's';
    const dKey = 'd';
    const fKey = 'f';
    const arrowUp = 'ArrowUp';
    const arrowDown = 'ArrowDown';

    useEffect(() => {
        const preventWindowScroll = (e: any) => {
            if ([arrowUp, arrowDown].includes(e.key))
                e.preventDefault();
        };

        window.addEventListener('keydown', preventWindowScroll);

        return () => {
            window.removeEventListener('keydown', preventWindowScroll);
        };
    });

    const blurForm = () => {
        if (!formEditable) return;

        setFormEditable(false);
    };

    const focusForm = () => {
        if (formEditable) return;

        setFormEditable(true);
    };

    const onCommentBlur = () => {
        if ((!formEditable && !audit?.completed) || userIsNotAuditor) return;

        onUpdateComment();
    };

    const scroll = (position: number) => {
        if (!content.current) {
            return;
        }

        document.querySelector('.conversation-form__scroll-content')?.scrollTo({
            left: 0,
            top: position - content.current.offsetTop,
            behavior: 'smooth'
        });
    };

    const onTabLoad = (boxes: BoxData[]) => {
        setQuestionBoxes(boxes);
    };

    const onShortcutClick = (questionBox: number) => {
        setCurrentPart(questionBox);

        if (!disabled) {
            setCurrentQuestion(0);
        }

        scroll(questionBoxes[questionBox].position);
    };

    const moveToQuestion = (question: number, part: number) => {
        if (question === currentQuestion && part === currentPart) return;

        const questionBox = questionBoxes[part];

        const questionCount = (parts[part].questions ?? []).length;

        const position = questionBox.position +
            (question === 1
                ? 52
                : (Math.floor(questionBox.height - 60) / questionCount) * question);

        setCurrentQuestion(question);
        setCurrentPart(part);

        scroll(position);
    };

    const moveUp = () => {
        const questions = parts[currentPart].questions;

        if (!questions) {
            return;
        }

        let question = currentQuestion - 1;
        let part = currentPart;

        if (question < 0 && currentPart !== 0) {
            part--;
            question = (parts[part].questions ?? []).length - 1;
        }

        moveToQuestion(Math.max(question, 0), part);
    };

    const moveDown = () => {
        const questions = parts[currentPart].questions;

        if (!questions) {
            return;
        }

        const questionCount = questions.length - 1;

        let question = currentQuestion + 1;
        let part = currentPart;

        if (question > questionCount && currentPart === parts.length - 1) {
            focusTextArea();

            return;
        }

        if (question > questionCount) {
            part++;
            question = 0;
        }

        moveToQuestion(question, part);
    };

    const answerQuestion = (part: number, question: number, answer: number) => {
        if (disabled) return;

        let newParts = [...parts];

        const questions = newParts[part].questions;

        if (!questions) return;

        const oldAnswer = questions[question].answer;

        if (questions[question].answer === undefined) {
            newParts = newParts.map((altPart, i) => {
                if (i === part) {
                    return {...altPart, questionsAnswered: altPart.questionsAnswered += 1};
                } else {
                    return altPart;
                }
            });
        }

        if (oldAnswer !== answer) {
            questions[question].answer = answer;

            setParts(newParts);

            onQuestionChange(questions[question], answer);
        }

        tabRef.current?.focus();
        setFormEditable(true);
    };

    const answerCurrentQuestion = (answer: number) => {
        answerQuestion(currentPart, currentQuestion, answer);
    };

    const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (!formEditable || disabled || currentQuestion === -1) return;

        switch (e.key) {
            case sKey:
                answerCurrentQuestion(0);
                break;
            case dKey:
                answerCurrentQuestion(1);
                break;
            case fKey:
                answerCurrentQuestion(2);
                break;
            case arrowUp:
                moveUp();
                break;
            case arrowDown:
                moveDown();
                break;
        }
    };

    const onQuestionAnswerChange = (part: number, question: number, answer: number) => {
        answerQuestion(part, question, answer);
        moveToQuestion(question, part);
    };

    const onAnswerClick = (part: number, question: number, answer: number) => {
        if (!formEditable || disabled) return;

        onQuestionAnswerChange(part, question, answer);
    };

    const onTextAreaKeyUp = (e: React.KeyboardEvent) => {
        e.stopPropagation();

        const cursorPos = inputRef.current!.selectionStart;

        if (cursorPos === 0 && lastCursorPos === 0 && e.key === 'ArrowUp') {
            inputRef.current?.blur();
            content.current?.focus();

            const part = parts.length - 1;

            moveToQuestion(parts[part].questions!.length - 1, part);
        }

        setLastCursorPos(cursorPos);
    };

    const focusTextArea = () => {
        inputRef.current?.focus();
        setCurrentQuestion(-1);
    };

    if (!parts.length) {
        return (
            <div className={'flex flex--justify-center'}>
                <CircleLoader/>
            </div>
        );
    }

    return (
        <BoxList onKeyRelease={onKeyPress} onShortcutClick={onShortcutClick} onLoad={onTabLoad} ref={tabRef} className={'conversation-box'}>
            <TabContentLeft>
                <Box>
                    <BoxHeader>
                        Fases van het protocol
                    </BoxHeader>

                    <BoxContent>
                        {parts.map((part, index) => {
                            return (
                                <FormPartShortcut
                                    part={part}
                                    active={currentPart === index}
                                    answered={part.questionsAnswered}
                                    total={part.questions!.length}
                                    index={index}
                                    key={index}/>
                            );
                        })}
                    </BoxContent>
                </Box>

                <a href={ntsUrl} target={'_blank'} rel="noreferrer">
                    <Button disabled={!ntsUrl}>
                        NTS Formulier
                    </Button>
                </a>

                <div className="toggle-box">
                    <div className="toggle-box__content">
                        <div className="toggle-box--card">
                            <label>Toon weging</label>
                            <label className="switch">
                                <input type="checkbox" onChange={() => setShowType(!showType)} checked={showType}/>
                                <span className="switch__slider switch__round"/>
                            </label>
                        </div>
                    </div>
                </div>

                {(scores.length > 0 && audit?.completed_at) ? <ResultsBox scores={scores}/> : <></>}
            </TabContentLeft>

            <TabContentRight next={next} ref={content} nextDisabled={!canGoNext}>
                <div onFocus={focusForm} onBlur={blurForm} onClick={focusForm}>
                    {parts.map((part, partIndex) => {
                        return (
                            <Box key={partIndex}>
                                <FormQuestionBox
                                    key={partIndex}
                                    partIndex={partIndex}
                                    currentPart={currentPart}
                                    part={part}
                                    currentQuestion={currentQuestion}
                                    onAnswerChange={onQuestionAnswerChange}
                                    onAnswerClick={onAnswerClick}
                                    showType={showType}/>
                            </Box>
                        );
                    })}
                </div>

                <div className={'conversation-form__comment'}>
                    <TextArea
                        className={'textarea-resize-vertical'}
                        ref={inputRef}
                        text={'Opmerking'}
                        placeholder={'Geen opmerking'}
                        id={'comment'}
                        form={commentForm}
                        onChange={onCommentChange}
                        onBlur={onCommentBlur}
                        disabled={audit?.completed || userIsNotAuditor}
                        onKeyUp={onTextAreaKeyUp}/>
                </div>
            </TabContentRight>
        </BoxList>
    );
};

export default ProtocolTab;
