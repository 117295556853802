import React, {useState, useEffect} from 'react';
import {useNavigate} from '@tanstack/react-router';
import Page from 'components/layout/Page';
import {useAuth} from 'components/contexts/AuthContext';
import {logError} from '../../../utils/devtool/DevTool';
import {createRoute, useParams, useSearch} from '@tanstack/react-router';
import {GuestRoute} from '../../../../routing/Auth';

export const LoginSSORoute = createRoute({
    getParentRoute: () => GuestRoute,
    path: '/login/sso/$centerAbbreviation',
    component: () => <LoginSSO />,
});

const LoginSSO = () => {
    const navigate = useNavigate();
    const {code} = useSearch({strict: false}) as {code: string};
    const {centerAbbreviation} = useParams({strict: false}) as {centerAbbreviation: string};

    const [error, setError] = useState<boolean>(false);

    const { loginWithoutCredentials } = useAuth();

    useEffect(() => {
        if(!code) {
            setError(true);
            return;
        }
        // get the REACT_APP_API_ENDPOINT from the .env file
        const endpoint = process.env.REACT_APP_API_ENDPOINT;
        fetch(`${endpoint}/api/auth/${centerAbbreviation}/callback?code=${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if(data.token) {
                    loginWithoutCredentials(data.token);
                    const navigateTo = sessionStorage.getItem('redirectTo') || '/';
                    sessionStorage.removeItem('redirectTo');
                    navigate({to: navigateTo});
                } else {
                    setError(true);
                }
            })
            .catch(e => {
                logError(e);
                setError(true);
            });
    }, [code, setError, loginWithoutCredentials]);

    return (
        <Page className="auth__page">
            <form className="auth__page__form">
                <div className="auth__page__form__row auth__page__form__row__meldkamer">
                    <div className="auth__page__form__row__col" />
                </div>

                <div className="auth__page__form__row">
                    <h1 className="title--black auth__page__form__title auth__page__form__row__col">Inloggen</h1>
                </div>

                {(error) ? <p>Oeps er is iets misgegaan. Neem contact op met de beheerder van uw meldkamer.</p> : <p>Een ogenblik geduld alstublieft.</p>}
            </form>
        </Page>
    );
};

export default LoginSSO;
